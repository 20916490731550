import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../common/LoadingSpinner";
import ModalLayout from "../../../common/ModalLayout";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import axiosService from "../../../../init/axios";
import {
  convertEnumToLabel,
  showErrorMessage,
  timeConverter,
} from "../../../../utils/helpers";
import { APIConfig } from "../../../../utils/constants/api.constants";
import { Button } from "@progress/kendo-react-buttons";

function DriverHistoryModal({ closeModal, selectedDriverRow }) {
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const columns = [
    { field: "chargeId", title: "Charge Id", width: 150 },
    { field: "tripId", title: "Trip Id", width: 150 },
    {
      field: "type",
      title: "Type",
      width: 150,
      cell: (props) => (
        <td>{convertEnumToLabel(props?.dataItem?.type) || "N.A."}</td>
      ),
    },
    {
      field: "subType",
      title: "Sub Type",
      width: 300,
      cell: (props) => (
        <td>{convertEnumToLabel(props?.dataItem?.subType) || "N.A."}</td>
      ),
    },
    {
      field: "status",
      title: "Status",
      width: 150,
      cell: (props) => (
        <td>{convertEnumToLabel(props?.dataItem?.status) || "N.A."}</td>
      ),
    },
    {
      field: "updatedBy",
      title: "Updated By",
      width: 200,
      cell: (props) => <td>{props?.dataItem?.updatedBy || "N.A."}</td>,
    },
    {
      field: "reason",
      title: "Reason",
      width: 250,
      cell: (props) => <td>{props?.dataItem?.reason || "N.A."}</td>,
    },
    {
      field: "subReason",
      title: "Sub Reason",
      width: 300,
      cell: (props) => <td>{props?.dataItem?.subReason || "N.A."}</td>,
    },
  ];

  function fetchDriverHistory() {
    setLoading(true);
    axiosService
      .get(
        process.env.REACT_APP_DRIVER_BASE_URL +
          APIConfig.driverManagement.getDriverHistory(
            selectedDriverRow?.driverId,
            selectedDriverRow?.chargeId
          )
      )
      .then((data) => {
        setHistoryData(data);
      })
      .catch((error) => {
        showErrorMessage(error);
      })
      .finally(() => setLoading(false));
  }
  useEffect(() => fetchDriverHistory(), []);
  return (
    <ModalLayout title={"Charge History"} onClose={closeModal}>
      <div className="tw-w-[1000px]">
        <>
          {loading ? (
            <div className="tw-flex tw-justify-center tw-items-center">
              <LoadingSpinner color="grey" height={70} width={70} />
            </div>
          ) : (
            <>
              <Grid data={historyData}>
                {columns?.map((column) => (
                  <Column
                    key={column?.id}
                    field={column?.field}
                    title={column?.title}
                    width={column?.width}
                    cell={column?.cell}
                  />
                ))}
              </Grid>
              <Button
                className="tw-mt-4"
                themeColor="primary"
                fillMode={"solid"}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </>
          )}
        </>
      </div>
    </ModalLayout>
  );
}

export default DriverHistoryModal;

import { Field, Form, FormElement } from "@progress/kendo-react-form";
import React, { useEffect, useState } from "react";
import {
  FormDatePicker,
  FormDropDownList,
  FormInput,
  FormMultiSelect,
} from "../../../utils/forms/FormComponents";
import {
  requiredArrayValidator,
  requiredValidator,
} from "../../../utils/forms/Validators";
import { Button } from "@progress/kendo-react-buttons";
import { useNavigate, useParams } from "react-router-dom";
import LoadingPanel from "../../common/LoadingPanel";
import { DriverConfig } from "../../../config/driverConfig";
import {
  checkMatchingRoles,
  getLabelValueObjectFromEnum,
  showToastMessage,
} from "../../../utils/helpers";
import axiosService from "../../../init/axios";
import { APIConfig } from "../../../utils/constants/api.constants";
import DocumentImageModal from "../../common/DocumentImageModal";
import { showErrorMessage } from "../../../utils/helpers";
import DocumentPdfModal from "../../common/DocumentPdfModal";
import FileUploadAndView from "../../common/FileUploadAndView";
import DriverAdditionalDetailsHome from "./driver-logs/DriverAdditionalDetailsHome";
import DriverBankDetailsModificationModal from "./DriverBankDetailsModificationModal";
import { useSelector } from "react-redux";
import { userPermissions } from "../../../config/userPermissionConfig";
import BackArrowIcon from "../../../assets/icons/BackArrowIcon";

const DriverProfileForm = () => {
  const [loading, setLoading] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [documentDetails, setDocumentDetails] = useState();
  const { driverId } = useParams();
  const [formDetails, setFormDetails] = useState({});
  const [driverContractImagePath, setDriverContractImagePath] = useState("");
  const [editBankDetailsModalOpen, setEditBankDetailsModalOpen] =
    useState(false);
  const [bankDetails, setBankDetails] = useState({});
  const navigate = useNavigate();
  const userRoles = useSelector((state) => state.auth?.userRoles);
  const regions = useSelector((state) => state.region?.mainRegions);
  const labelRegions = useSelector((state) => state.region?.labelRegions);

  useEffect(() => {
    // API Call to fetch driver details in the form using driverId
    getDriverDetail();
  }, []);

  const getDriverDetail = async () => {
    setLoading(true);
    await axiosService
      .get(
        process.env.REACT_APP_DRIVER_BASE_URL +
          APIConfig.driverManagement.getDriverDetails(driverId)
      )
      .then((data) => {
        if (data?.data) {
          const driverDetails = { ...data?.data };
          setBankDetails({
            accountNumber: driverDetails?.accountNumber,
            ifsc: driverDetails?.ifsc,
            accountHoldersName: driverDetails?.accountHoldersName,
            cancelledCheque: driverDetails?.cancelledCheque,
          });
          driverDetails["dateOfBirth"] = new Date(data?.data?.dateOfBirth);

          if (driverDetails["transmissionTypePreferences"]?.length > 0) {
            const transmissionTypeArr = [];
            for (let value of driverDetails["transmissionTypePreferences"]) {
              const item = DriverConfig.transmissionTypePreferences.filter(
                (item) => item.value === value
              )[0];
              if (item) {
                transmissionTypeArr.push(item);
              }
            }
            driverDetails["transmissionTypePreferences"] = transmissionTypeArr;
          }

          if (driverDetails["tripTypePreferences"]?.length > 0) {
            const tripTypeArr = [];
            for (let value of driverDetails["tripTypePreferences"]) {
              const item = DriverConfig.tripTypePreferences.filter(
                (item) => item.value === value
              )[0];
              if (item) {
                tripTypeArr.push(item);
              }
            }
            driverDetails["tripTypePreferences"] = tripTypeArr;
          }

          if (driverDetails["carTypePreferences"]?.length > 0) {
            const carTypeArr = [];
            for (let value of driverDetails["carTypePreferences"]) {
              const item = DriverConfig.carTypePreferences.filter(
                (item) => item.value === value
              )[0];
              if (item) {
                carTypeArr.push(item);
              }
            }
            driverDetails["carTypePreferences"] = carTypeArr;
          }

          if (driverDetails["clientAccountTypes"]?.length > 0) {
            const clientTypeArr = [];
            for (let value of driverDetails["clientAccountTypes"]) {
              const item = DriverConfig.clientAccountTypes.filter(
                (item) => item.value === value
              )[0];
              if (item) {
                clientTypeArr.push(item);
              }
            }
            driverDetails["clientAccountTypes"] = clientTypeArr;
          }

          if (driverDetails?.documents && driverDetails?.documents.length > 0) {
            const documents = driverDetails?.documents;
            documents.forEach((doc, index) => {
              switch (doc?.tag) {
                case "AADHAAR_CARD_FRONT":
                  driverDetails["aadharCardFrontImage"] = doc;
                  break;

                case "AADHAAR_CARD_BACK":
                  driverDetails["aadharCardBackImage"] = doc;
                  break;

                case "DRIVING_LICENSE":
                  driverDetails["drivingLicenseImage"] = doc;
                  break;

                case "PAN_CARD":
                  driverDetails["panCardImage"] = doc;
                  break;

                case "VACCINATION_CERTIFICATE":
                  driverDetails["vaccinationCertificateImage"] = doc;
                  break;

                case "DRIVER_CONTRACT":
                  driverDetails["driverContractImage"] = doc;
                  setDriverContractImagePath(doc?.filePath);
                  break;

                default:
                  break;
              }
            });
          }

          if (
            driverDetails?.profileAlerts &&
            driverDetails?.profileAlerts?.length > 0
          ) {
            const alerts = driverDetails?.profileAlerts;
            alerts?.forEach((alert) => {
              let alertObj = DriverConfig.driverAlerts.find(
                (a) => a.code === alert.code && a.type === alert.type
              );
              if (alertObj) {
                driverDetails[alertObj.label] = alert?.message;
              }
            });
          }

          if (driverDetails?.metadata?.languagesKnown?.length > 0) {
            const languages = [];
            for (let value of driverDetails?.metadata?.languagesKnown) {
              const item = DriverConfig.languages.filter(
                (item) => item.value === value
              )[0];
              if (item) {
                languages.push(item);
              }
            }
            driverDetails["driverKnownLanguages"] = languages;
          }
          driverDetails["driverExperienceYears"] =
            driverDetails?.metadata?.experienceDetails?.years || 0;
          driverDetails["driverExperienceMonths"] =
            driverDetails?.metadata?.experienceDetails?.months || 0;
          driverDetails["homeRegionId"] = {
            label: driverDetails["homeRegionId"] || "",
            value: driverDetails["homeRegionId"] || "",
          };

          driverDetails["packageTripsAllowed"] =
            driverDetails.packageTripsAllowed
              ? { label: "Yes", value: true }
              : { label: "No", value: false };

          const updatedDriverDetails = getLabelValueObjectFromEnum(
            ["driverPayoutType", "bgvStatus", "testDriveStatus", "blocked"],
            driverDetails,
            "driver"
          );
          setFormDetails(updatedDriverDetails);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };
  
  const getImage = async (title, imagePath, type = "image") => {
    if (!imagePath) {
      showToastMessage(`${title} not available.`, false);
    } else {
      await axiosService
        .get(
          process.env.REACT_APP_DRIVER_BASE_URL +
            APIConfig.driverManagement.getDriverDocumentImage +
            "?file-path=" +
            imagePath
        )
        .then((data) => {
          if (data?.data) {
            openDocumentModal(title, data?.data?.preSignedUrl, type);
          }
        })
        .catch((error) => {
          setLoading(false);
          showErrorMessage(error);
        });
    }
  };

  const openDocumentModal = (title, imageUrl, type) => {
    if (type === "pdf") {
      setDocumentDetails({
        title: title,
        url: imageUrl,
      });
      setPdfModalOpen(true);
    } else {
      setDocumentDetails({
        title: title,
        imageUrl: imageUrl,
      });
      setModalOpen(true);
    }
  };

  const closeModalDocument = () => {
    setModalOpen(false);
  };

  const closePdfModalDocument = () => {
    setPdfModalOpen(false);
  };

  const openEditBankDetailsModal = () => {
    setEditBankDetailsModalOpen(true);
  };

  const closeEditBankDetailsModal = (e, refresh) => {
    setEditBankDetailsModalOpen(false);
    if (refresh) {
      getDriverDetail();
    }
  };

  const handleSubmit = async (dataItem) => {
    setLoading(true);
    const formValues = {
      bgvStatus: dataItem?.bgvStatus?.value,
      testDriveStatus: dataItem?.testDriveStatus?.value,
      transmissionTypes: dataItem?.transmissionTypePreferences?.map(
        (item) => item.value
      ),
      carTypes: dataItem?.carTypePreferences?.map((item) => item.value),
      tripTypes: dataItem?.tripTypePreferences?.map((item) => item.value),
      clientAccountTypes: dataItem?.clientAccountTypes?.map((item)=>item.value),
      block: dataItem?.blocked?.value,
      homeRegionId:
        dataItem?.homeRegionId?.regionId || dataItem?.homeRegionId?.value,
      packageTripsAllowed: dataItem?.packageTripsAllowed?.value || false,
    };
    if (dataItem?.driverPayoutType?.value !== "") {
      formValues["payoutType"] = dataItem?.driverPayoutType?.value;
    }

    await axiosService
      .put(
        process.env.REACT_APP_DRIVER_BASE_URL +
          APIConfig.driverManagement.saveDriverDetails(driverId),
        JSON.stringify(formValues)
      )
      .then((data) => {
        setLoading(false);
        showToastMessage("Driver details updated successfully");
        // navigate('/home/driver?pageNumber=' + searchParams.get('pageNumber'));
        navigate("/home/driver");
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  const blockDriver = async () => {
    setLoading(true);
    await axiosService
      .put(
        process.env.REACT_APP_DRIVER_BASE_URL + "/admin/driver/" + driverId,
        JSON.stringify({ block: true })
      )
      .then((data) => {
        setLoading(false);
        showToastMessage("Driver blocked successfully");
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  const onUploadDriverContract = async (selectedFile) => {
    setFileUploadLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("tag", "DRIVER_CONTRACT");
    formData.append("driverId", driverId);
    await axiosService
      .post(
        process.env.REACT_APP_DRIVER_BASE_URL +
          APIConfig.driverManagement.uploadDriverContract,
        formData
      )
      .then((data) => {
        setDriverContractImagePath(data?.data?.filePath);
        showToastMessage("File uploaded successfully");
        setFileUploadLoading(false);
      })
      .catch((error) => {
        showErrorMessage(error);
        setFileUploadLoading(false);
      });
  };

  return (
    <>
      <div className="driver-profile-form">
        <div className="dflex jcsp">
          <span
            className="tw-cursor-pointer"
            onClick={() => navigate("/home/driver")}
          >
            <BackArrowIcon />
          </span>
          {formDetails?.profileAlerts?.length > 0 && (
            <div className="driver-alerts">
              <h4 style={{ color: "red" }}>
                <img
                  src={require("../../../assets/warning-sign.png")}
                  alt="Warning"
                  width={50}
                />{" "}
                Account Alert
              </h4>
            </div>
          )}
          <Button
            themeColor={"primary"}
            onClick={openEditBankDetailsModal}
            disabled={
              !checkMatchingRoles(
                userRoles,
                userPermissions.bankDetailsModification
              ) || loading
            }
          >
            Edit Bank Details
          </Button>
        </div>
        <br />
        {loading ? (
          <LoadingPanel />
        ) : (
          <Form
            onSubmit={handleSubmit}
            initialValues={formDetails}
            key={JSON.stringify(formDetails)}
            render={(formRenderProps) => (
              <FormElement
                style={{
                  width: "100%",
                  background: "#fafafa",
                  padding: "0px 16px 0px 16px",
                  border: "1px solid rgba(0, 0, 0, 0.08)",
                }}
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <div className="left col-12 col-md-8 ">
                      {/* Driver Name */}
                      <Field
                        id={"fullName"}
                        name={"fullName"}
                        label={"Driver Name"}
                        disabled={true}
                        component={FormInput}
                        // validator={nameValidator}
                      />
                    </div>
                    <div className="right col-12 col-md-4"></div>

                    {/* Driver Id */}
                    <div className="left col-12 col-md-8 ">
                      <Field
                        id={"driverId"}
                        name={"driverId"}
                        label={"Driver Id"}
                        disabled={true}
                        component={FormInput}
                      />
                    </div>
                    <div className="right col-12 col-md-4"></div>

                    {/* Phone Number */}
                    <div className="left col-12 col-md-8 ">
                      <Field
                        id={"mobileNumber"}
                        name={"mobileNumber"}
                        label={"Phone Number"}
                        disabled={true}
                        component={FormInput}
                        // validator={phoneValidator}
                      />
                    </div>
                    <div className="right col-12 col-md-4"></div>

                    {/* Date Of Birth */}
                    <div className="left col-12 col-md-8 ">
                      <Field
                        id={"dateOfBirth"}
                        name={"dateOfBirth"}
                        label={"DOB"}
                        disabled={true}
                        component={FormDatePicker}
                        // validator={dateValidator}
                      />
                    </div>

                    {/* PAN Number */}
                    <div className="left col-12 col-md-8 ">
                      <Field
                        id={"panCardNumber"}
                        name={"panCardNumber"}
                        label={"PAN Number"}
                        disabled={true}
                        component={FormInput}
                        // validator={requiredValidator}
                      />
                    </div>
                    {/* <div className='right col-12 col-md-4 form-side-url'>
                      <span className='document-link-url' onClick={() => openDocumentModal('PAN Document', getImage(formDetails?.panCardImagePath))}>PAN Document Link</span>
                    </div> */}

                    {/* Aadhar Number */}
                    <div className="left col-12 col-md-8 ">
                      <Field
                        id={"aadhaarCardNumber"}
                        name={"aadhaarCardNumber"}
                        label={"Aadhar Number"}
                        disabled={true}
                        component={FormInput}
                        // validator={requiredValidator}
                      />
                    </div>
                    {/* <div className='right col-12 col-md-4 form-side-url'>
                      <span className='document-link-url' onClick={() => openDocumentModal('Aadhar Document Link', getImage(formDetails?.aadhaarCardFrontImagePath))}>Aadhar Document Link</span>
                    </div> */}

                    {/* Driving License Number */}
                    <div className="left col-12 col-md-8 ">
                      <Field
                        id={"drivingLicenseNumber"}
                        name={"drivingLicenseNumber"}
                        label={"Driving License Number"}
                        disabled={true}
                        component={FormInput}
                        // validator={requiredValidator}
                      />
                    </div>
                    {/* <div className='right col-12 col-md-4 form-side-url'>
                      <span className='document-link-url' onClick={() => openDocumentModal('Driving License Number', getImage(formDetails?.drivingLicenseImagePath))}>Driving License Link</span>
                    </div> */}
                  </div>

                  <div className="col-sm-12 col-md-6">
                    {/* Bank Account Number Name */}

                    <div className="left col-12 col-md-8 ">
                      <Field
                        id={"accountNumber"}
                        name={"accountNumber"}
                        label={"Bank Account Number"}
                        disabled={true}
                        component={FormInput}
                        // validator={nameValidator}
                      />
                    </div>
                    <div className="right col-12 col-md-4">
                      {formDetails?.accountNumberAlert && (
                        <div>
                          <h6>{formDetails?.accountNumberAlert}</h6>
                        </div>
                      )}
                    </div>

                    {/* IFSC Code */}
                    <div className="left col-12 col-md-8 ">
                      <Field
                        id={"ifsc"}
                        name={"ifsc"}
                        label={"IFSC Code"}
                        disabled={true}
                        component={FormInput}
                        // validator={nameValidator}
                      />
                    </div>
                    <div className="right col-12 col-md-4"></div>

                    {/* Account Holder Name */}
                    <div className="left col-12 col-md-8 ">
                      <Field
                        id={"accountHoldersName"}
                        name={"accountHoldersName"}
                        label={"Account Holder Name"}
                        disabled={true}
                        component={FormInput}
                        // validator={nameValidator}
                      />
                    </div>
                    <div className="right col-12 col-md-4"></div>

                    {/* Earnings Plan */}
                    <div className="left col-12 col-md-8 ">
                      <Field
                        id={"driverPayoutType"}
                        name={"driverPayoutType"}
                        label={"Earnings Plan"}
                        component={FormDropDownList}
                        data={DriverConfig.driverPayoutType}
                        disabled={formDetails?.driverPayoutType?.value !== ""}
                        textField="label"
                        dataItemKey="value"
                        // validator={requiredValidator}
                      />
                    </div>
                    <div className="right col-12 col-md-4"></div>

                    {/* Car Transmission Type Preference */}
                    <div className="left col-12 col-md-8 ">
                      <Field
                        id={"transmissionTypePreferences"}
                        name={"transmissionTypePreferences"}
                        label={"Transmission Type Preference"}
                        component={FormMultiSelect}
                        data={DriverConfig.transmissionTypePreferences}
                        textField="label"
                        dataItemKey="value"
                        validator={requiredArrayValidator}
                      />
                    </div>
                    <div className="right col-12 col-md-4"></div>

                    {/* Car Type Preference */}
                    <div className="left col-12 col-md-8 ">
                      <Field
                        id={"carTypePreferences"}
                        name={"carTypePreferences"}
                        label={"Car Type Preference"}
                        component={FormMultiSelect}
                        data={DriverConfig.carTypePreferences}
                        textField="label"
                        dataItemKey="value"
                        validator={requiredArrayValidator}
                      />
                    </div>
                    <div className="right col-12 col-md-4"></div>

                    {/* Trip Type */}
                    <div className="left col-12 col-md-8 ">
                      <Field
                        id={"tripTypePreferences"}
                        name={"tripTypePreferences"}
                        label={"Trip Type Preference"}
                        component={FormMultiSelect}
                        data={DriverConfig.tripTypePreferences}
                        textField="label"
                        dataItemKey="value"
                        validator={requiredArrayValidator}
                      />
                    </div>
                    <div className="right col-12 col-md-4"></div>

                    {/* Account Type Eligibility */}
                    <div className="left col-12 col-md-8 ">
                      <Field
                        id={"clientAccountTypes"}
                        name={"clientAccountTypes"}
                        label={"Account Type Eligibility"}
                        component={FormMultiSelect}
                        data={DriverConfig?.clientAccountTypes}
                        textField="label"
                        dataItemKey="value"
                        validator={requiredArrayValidator}
                      />
                    </div>
                    <div className="right col-12 col-md-4"></div>
                  </div>
                </div>
                <div className="row tw-my-5">
                  <div className="col-sm-12">
                    <div className="left col-12 col-sm-2">
                      <span
                        className="document-link-url"
                        onClick={() =>
                          getImage(
                            "PAN Card Image",
                            formDetails?.panCardImage?.filePath
                          )
                        }
                      >
                        PAN Card Image
                      </span>
                    </div>
                    <div className="left col-12 col-sm-2">
                      <span
                        className="document-link-url"
                        onClick={() =>
                          getImage(
                            "Aadhar Card Front Image",
                            formDetails?.aadharCardFrontImage?.filePath
                          )
                        }
                      >
                        Aadhar Card Front Image
                      </span>
                    </div>
                    <div className="left col-12 col-sm-2">
                      <span
                        className="document-link-url"
                        onClick={() =>
                          getImage(
                            "Aadhar Card Back Image",
                            formDetails?.aadharCardBackImage?.filePath
                          )
                        }
                      >
                        Aadhar Card Back Image
                      </span>
                    </div>
                    <div className="left col-12 col-sm-2">
                      <span
                        className="document-link-url"
                        onClick={() =>
                          getImage(
                            "Driving License Image",
                            formDetails?.drivingLicenseImage?.filePath
                          )
                        }
                      >
                        Driving License Image
                      </span>
                    </div>
                    <div className="left col-12 col-sm-2">
                      <span
                        className="document-link-url"
                        onClick={() =>
                          getImage(
                            "Vaccination Certificate",
                            formDetails?.vaccinationCertificateImage?.filePath,
                            "pdf"
                          )
                        }
                      >
                        Vaccination Certificate
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row tw-max-w-5xl tw-pl-4">
                  <div className="col-sm-6 col-md-3">
                    {/* 3rd Party Internal */}
                    <div>BGV Third Party</div>
                    <div>
                      <Field
                        id={"bgvStatus"}
                        name={"bgvStatus"}
                        component={FormDropDownList}
                        data={DriverConfig.bgvStatus}
                        textField="label"
                        dataItemKey="value"
                        disabled={false}
                        validator={requiredValidator}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    {/* Driving Test */}
                    <div>Driving Test</div>
                    <div>
                      <Field
                        id={"testDriveStatus"}
                        name={"testDriveStatus"}
                        component={FormDropDownList}
                        data={DriverConfig.testDriveStatus}
                        textField="label"
                        dataItemKey="value"
                        validator={requiredValidator}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    {/* Driver Status */}
                    <div>Activity Status</div>
                    <div>
                      <Field
                        id={"blocked"}
                        name={"blocked"}
                        component={FormDropDownList}
                        data={DriverConfig.blocked}
                        textField="label"
                        dataItemKey="value"
                        // validator={requiredValidator}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    {/* Driver Home Region ID */}
                    <div>Driver Home Region ID</div>
                    <div>
                      <Field
                        id={"homeRegionId"}
                        name={"homeRegionId"}
                        component={FormDropDownList}
                        data={labelRegions || [{ label: "", value: "" }]}
                        textField="value"
                        dataItemKey="value"
                        // validator={requiredValidator}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className="tw-grid tw-grid-cols-6 mx-3 tw-gap-5">
                  <div className="tw-col-span-1 tw-flex tw-items-center">
                    <b>Driver Contract</b>
                  </div>
                  <div className="">
                    <FileUploadAndView
                      onUploadDriverContract={onUploadDriverContract}
                      driverContractImagePath={driverContractImagePath}
                      getImage={getImage}
                      loading={fileUploadLoading}
                    />
                  </div>
                </div>
                <div className="tw-grid tw-grid-cols-6 mx-3 tw-gap-5">
                  <div className="tw-col-span-1 tw-flex tw-items-center">
                    <b>Package Eligibility</b>
                  </div>
                  <div className="">
                    <Field
                      id={"packageTripsAllowed"}
                      name={"packageTripsAllowed"}
                      component={FormDropDownList}
                      data={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      textField="label"
                      dataItemKey="value"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                  }}
                >
                  <div className="k-form-buttons">
                    <Button
                      themeColor={"primary"}
                      type={"submit"}
                      disabled={
                        !formRenderProps.valid || !formRenderProps.modified
                      }
                    >
                      Save
                    </Button>
                  </div>

                  <div style={{ marginRight: "10px" }}>
                    <Button onClick={formRenderProps.onFormReset}>Clear</Button>{" "}
                    &nbsp;&nbsp;
                    <Button onClick={() => navigate("/home/driver")}>
                      Cancel
                    </Button>
                  </div>
                </div>
                <br />
              </FormElement>
            )}
          />
        )}
        {modalOpen && (
          <DocumentImageModal
            {...documentDetails}
            onClose={closeModalDocument}
          />
        )}
        {pdfModalOpen && (
          <DocumentPdfModal
            {...documentDetails}
            onClose={closePdfModalDocument}
          />
        )}
        {editBankDetailsModalOpen && (
          <DriverBankDetailsModificationModal
            bankDetails={bankDetails}
            onClose={closeEditBankDetailsModal}
            driverId={driverId}
          />
        )}
      </div>
      <br />
      <div className="driver-additional-details-section">
        <DriverAdditionalDetailsHome
          driverId={driverId}
          driverDetails={formDetails}
        />
      </div>
    </>
  );
};

export default DriverProfileForm;

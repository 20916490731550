export const mapDefaults = {
  fleetViewMap: {
    center: {
      lat: 28.679079,
      lng: 77.06971,
    },
    options: { disableDefaultUI: true, clickableIcons: false },
    zoom: 10,
  },
  addTaskMap: {
    center: {
      lat: 28.679079,
      lng: 77.06971,
    },
    options: { disableDefaultUI: true, clickableIcons: false },
    zoom: 15,
  },
  tripDetails: {
    zoom: 8,
    options: { disableDefaultUI: true, clickableIcons: false },
  },
  driverTimeline: {
    zoom: 15,
    center: {
      lat: 28.679079,
      lng: 77.06971,
    },
    options: { disableDefaultUI: true, clickableIcons: false },
  },
  statusModificationMap: {
    center: {
      lat: 28.679079,
      lng: 77.06971,
    },
    options: { disableDefaultUI: true, clickableIcons: false },
    zoom: 15,
  },
};

export const pathsWithFiltered = [
  "/home/driver",
  "/home/trip",
  "/home/pricing",
];

export const libraries = ["places"];

export const defaultActivityState = {
  CURRENT: "CURRENT",
  UPCOMING: "UPCOMING",
};

export const defaultDriverData = {
  driverSessions: [],
  currentDriverLocation: {},
};

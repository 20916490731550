export const isValidRegistrationNo = (regNo) => {
    if (!regNo || typeof regNo !== "string" || regNo.trim().length === 0) {
      return false;
    }

    const patterns = [
      /^[A-Z]{2}[0-9]{2}[A-HJ-NP-Z]{1,2}[0-9]{4}$/,
      /^[0-9]{2}BH[0-9]{4}[A-HJ-NP-Z]{1,2}$/,
      /^[a-zA-Z]{2}[0-9]{1,2}([a-zA-Z]{1,3})?[0-9]{4}$/,
      /^[A-Z]{2}[ -]{0,1}[0-9]{2}[ -]{0,1}[0-9]{1,4}$/,
    ];

    return patterns.some((pattern) => pattern.test(regNo));
  };



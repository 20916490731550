export default function FiltersIcon({ fill = "#2756B3" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="7"
      viewBox="0 0 14 7"
      fill="none"
    >
      <path
        d="M11.5 3.5C11.5 3.63261 11.4473 3.75979 11.3536 3.85355C11.2598 3.94732 11.1326 4 11 4H3C2.86739 4 2.74021 3.94732 2.64645 3.85355C2.55268 3.75979 2.5 3.63261 2.5 3.5C2.5 3.36739 2.55268 3.24021 2.64645 3.14645C2.74021 3.05268 2.86739 3 3 3H11C11.1326 3 11.2598 3.05268 11.3536 3.14645C11.4473 3.24021 11.5 3.36739 11.5 3.5ZM13.5 0H0.5C0.367392 0 0.240215 0.0526785 0.146447 0.146447C0.0526784 0.240215 0 0.367392 0 0.5C0 0.632608 0.0526784 0.759785 0.146447 0.853553C0.240215 0.947321 0.367392 1 0.5 1H13.5C13.6326 1 13.7598 0.947321 13.8536 0.853553C13.9473 0.759785 14 0.632608 14 0.5C14 0.367392 13.9473 0.240215 13.8536 0.146447C13.7598 0.0526785 13.6326 0 13.5 0ZM8.5 6H5.5C5.36739 6 5.24021 6.05268 5.14645 6.14645C5.05268 6.24021 5 6.36739 5 6.5C5 6.63261 5.05268 6.75979 5.14645 6.85355C5.24021 6.94732 5.36739 7 5.5 7H8.5C8.63261 7 8.75979 6.94732 8.85355 6.85355C8.94732 6.75979 9 6.63261 9 6.5C9 6.36739 8.94732 6.24021 8.85355 6.14645C8.75979 6.05268 8.63261 6 8.5 6Z"
        fill={fill}
      />
    </svg>
  );
}

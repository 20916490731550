import BulkOperationIcon from "../assets/icons/BulkOperationIcon";
import CouponManagementIcon from "../assets/icons/CouponManagementIcon";
import CustomerManagementIcon from "../assets/icons/CustomerManagementIcon";
import DriverManagementIcon from "../assets/icons/DriverManagementIcon";
import PricingLogicManagementIcon from "../assets/icons/PricingLogicManagementIcon";
import RegionManagementIcon from "../assets/icons/RegionManagementIcon";
import SavedAddressIcon from "../assets/icons/SavedAddressIcon";
import SubscriptionIcon from "../assets/icons/SubscriptionIcon";
import TripManagementIcon from "../assets/icons/TripManagementIcon";
import { checkMatchingRoles } from "../utils/helpers";
import { userPermissions } from "./userPermissionConfig";

export const getDrawerConfig = (userRoles, tripManagementQueryParams) => [
  {
    text: "Trip Management",
    selected: false,
    route: [
      "/home/trip" + tripManagementQueryParams,
      "/home/trip/tripId/details",
      "/home/trip/tripId/assign-driver",
    ],
    icon: TripManagementIcon,
    showInSidebar: true,
  },
  {
    text: "Driver Management",
    selected: false,
    route: [
      "/home/driver",
      "/home/driver/driverId/profile",
      "/home/driver/driverId/account",
      "/home/driver/driverId/online-activity",
    ],
    icon: DriverManagementIcon,
    showInSidebar: true,
    children: [
      {
        text: "All Drivers",
        route: "/home/driver/all",
        showInSidebar: true,
      },
      {
        text: "Fleet View",
        route: "/home/driver",
        showInSidebar: true,
      },
      {
        text: "Driver Payout",
        route: "/home/driver/payout",
        showInSidebar: true,
      },
    ],
  },
  {
    text: "Pricing Logic Management",
    selected: false,
    route: ["/home/pricing"],
    icon: PricingLogicManagementIcon,
    showInSidebar: true,
  },
  {
    text: "Customer Management",
    selected: false,
    route: ["/home/customer-management"],
    icon: CustomerManagementIcon,
    showInSidebar: true,
  },
  {
    text: "Bulk Operations",
    selected: false,
    route: ["/home/operations"],
    icon: BulkOperationIcon,
    showInSidebar: true,
  },
  {
    text: "Coupon Management",
    selected: false,
    route: ["/home/coupon"],
    icon: CouponManagementIcon,
    showInSidebar: true,
    children: [
      {
        text: "Grievance Coupon",
        route: "/home/coupon/grievance",
        showInSidebar: true,
      },
      {
        text: "Referral Coupon",
        route: "/home/coupon/referral",
        showInSidebar: true,
      },
    ],
  },
  {
    text: "Subscriptions",
    selected: false,
    route: ["/home/subscriptions", "/home/subscriptions/subId/details"],
    icon: SubscriptionIcon,
    showInSidebar: true,
  },
  {
    text: "Saved Addresses",
    selected: false,
    route: ["/home/addresses"],
    icon: SavedAddressIcon,
    showInSidebar: true,
  },
  {
    text: "Region management",
    selected: false,
    route: ["/home/region-management"],
    icon: RegionManagementIcon,
    showInSidebar: checkMatchingRoles(
      userRoles,
      userPermissions.regionMangementModification
    ),
  },
];

import React, { useState, useEffect } from "react";
import ModalLayout from "../../../../common/ModalLayout";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import LoadingSpinner from "../../../../common/LoadingSpinner";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import axiosService from "../../../../../init/axios";
import { convertEnumToLabel, convertLabelToEnum, showErrorMessage, showToastMessage } from "../../../../../utils/helpers";
import { set } from "lodash";

function AddPricingModal({setRefresh, tripId, tripDetails, closeModal }) {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    chargeType: "",
    amount: "",
  });
  const [amount, setAmount] = useState("0");
  const [dropdownData, setDropdownData] = useState([])
  // Fetch dropdown data and amount
  useEffect(() => {
    setLoading(true);

    const fetchConfigData = async () => {
      try {
        const url =
          process.env.REACT_APP_DRIVER_BASE_URL +
          APIConfig.driverManagement.dropdownConfig(
            "DRIVER_CANCELLATION_PATHFINDER_DROPDOWNS"
          );
        const response = await axiosService.get(url);
        const addUserCharge = response?.data?.addUserCharge || [];
        setDropdownData(addUserCharge);
      } catch (error) {
        showErrorMessage(error);
      }
    };

    const fetchAmount = async () => {
      try {
        const url =
          process.env.REACT_APP_PRICING_BASE_URL +
          APIConfig.tripManagement.getCancellationCharge(
            tripDetails?.derivedTripType,
            tripDetails?.parentRegionId
          );
        const response = await axiosService.get(url);
        setFormData(prevData=>({ ...prevData, amount: response?.data?.vacConfig || "0"}));
      } catch (error) {
        console.error("Error fetching amount:", error);
      }
    };

    Promise.all([fetchConfigData(), fetchAmount()]).finally(() =>
      setLoading(false)
    );
  }, [tripDetails]);

  useEffect(()=>{
    const find = dropdownData?.find(item=>item?.key === formData?.chargeType)
    const amount = formData?.amount[find?.amountKey] || "0";
    setAmount(amount);
  },[formData?.chargeType])

  const handleChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleSubmit = () => {
    const formattedData = {
        tripId,
        chargeType: formData.chargeType,
    }
    setLoading(true);
    const url = process.env.REACT_APP_TRIP_BASE_URL + APIConfig.tripManagement.addUserCharge;
    axiosService
      .put(url, formattedData)
      .then((e) => {
        showToastMessage("Pricing added successfully");
        setRefresh((prevState) => (!prevState ? true : true));
        closeModal();
      }).catch((err) => {
        showErrorMessage(err);
      }).finally(() => setLoading(false));
  };

  return (
    <ModalLayout title="Add Pricing" onClose={closeModal}>
      <div className="tw-w-[700px]">
        {loading ? (
          <div className="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
            <LoadingSpinner height={70} width={70} color={"grey"} />
          </div>
        ) : (
          <>
            <div className="tw-flex tw-gap-4 tw-mb-4">
              {/* Charge Type */}
              <div className="tw-w-1/2">
                <label className="tw-font-bold tw-text-sm">Charge Type</label>
                <DropDownList
                  data={dropdownData?.map(item=> item && convertEnumToLabel(item?.key)) || []}
                  value={convertEnumToLabel(formData?.chargeType) || ""}
                  onChange={(e) => handleChange("chargeType", convertLabelToEnum(e.target.value) || "")}
                />
              </div>
              {/* Amount */}
              <div className="tw-w-1/2">
                <label className="tw-font-bold tw-text-sm">Amount</label>
                <Input type="text" value={amount} disabled />
              </div>
            </div>

            <Button
              fillMode="solid"
              themeColor="primary"
              className="tw-px-6"
              onClick={handleSubmit}
              disabled={!formData?.chargeType}
            >
              Submit
            </Button>
          </>
        )}
      </div>
    </ModalLayout>
  );
}

export default AddPricingModal;

import closeBtn from "../../assets/closeButton.svg";
export default function Sidesheet({
  heading,
  Label,
  showSheet,
  buttonStyles = {},
  handleCloseSheet,
  handleShowSheet,
  children,
}) {
  return (
    <>
      <button
        style={buttonStyles}
        className="tw-flex tw-items-center tw-gap-2 border tw-px-4 tw-py-2 tw-text-sm tw-rounded-md"
        onClick={handleShowSheet}
      >
        {Label}
      </button>
      {showSheet && (
        <aside
          onClick={handleCloseSheet}
          className="tw-bg-black/50 tw-backdrop-blur tw-fixed tw-inset-0"
        ></aside>
      )}
      <div
        className={`tw-bg-white tw-shadow tw-duration-300 tw-transition-transform tw-top-0 p-10 tw-fixed tw-right-0 tw-w-full tw-max-w-xl tw-h-full tw-overflow-y-auto ${
          showSheet ? "tw-translate-x-0" : "tw-translate-x-[100%]"
        }`}
      >
        <div className="tw-flex tw-sticky border tw-bg-white tw-z-10 tw-top-0 tw-p-5 tw-items-center tw-justify-between">
          <span className="tw-text-xl tw-font-semibold">{heading}</span>
          <img
            src={closeBtn}
            className="tw-cursor-pointer"
            onClick={handleCloseSheet}
          />
        </div>
        <div className="tw-p-5">{children}</div>
      </div>
    </>
  );
}

export default function RegionManagementIcon({ fill = "#0F0F10" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.38079 2.11967C7.4817 1.23505 6.26947 0.741529 5.00816 0.746621C3.74685 0.751713 2.53865 1.255 1.64673 2.14686C0.754809 3.03872 0.251436 4.24689 0.246256 5.5082C0.241077 6.76951 0.73451 7.98177 1.61908 8.88092C2.06171 9.33086 2.58904 9.6887 3.17066 9.93381C3.75228 10.1789 4.37668 10.3064 5.00783 10.309C5.63899 10.3116 6.26441 10.1892 6.84802 9.94891C7.43163 9.70858 7.96189 9.35509 8.4082 8.9088C8.85452 8.46252 9.20805 7.93229 9.44842 7.34869C9.68878 6.7651 9.81122 6.13968 9.80868 5.50853C9.80613 4.87738 9.67864 4.25297 9.43357 3.67133C9.1885 3.0897 8.8307 2.56234 8.38079 2.11967ZM3.59954 5.24483L4.52064 4.16014L5.92033 4.41608L6.39892 5.75577L5.47783 6.84046L4.07814 6.58452L3.59954 5.24483ZM8.84064 3.75139C9.10421 4.33299 9.2327 4.96671 9.21647 5.60504C9.20023 6.24336 9.03969 6.86973 8.74689 7.43717L6.11532 6.95717L8.84064 3.75139ZM8.55189 3.22264L6.82032 5.26077L5.40611 1.30124C6.38126 1.39249 7.29355 1.82276 7.98423 2.51717C8.19786 2.7316 8.38814 2.96808 8.55189 3.22264ZM2.01704 2.51952C2.75818 1.77316 3.75297 1.33291 4.80376 1.28624L5.70329 3.80436L1.56658 3.04921C1.702 2.85989 1.85265 2.68196 2.01704 2.51717V2.51952ZM1.25017 3.56342L3.88173 4.04342L1.1597 7.24921C0.896122 6.66761 0.767634 6.03389 0.78387 5.39556C0.800107 4.75724 0.960646 4.13087 1.25345 3.56342H1.25017ZM1.44751 7.77796L3.17908 5.73983L4.59329 9.69936C3.61814 9.6081 2.70585 9.17784 2.01517 8.48342C1.80187 8.26895 1.6119 8.03247 1.44845 7.77796H1.44751ZM7.98236 8.48108C7.24098 9.22677 6.24616 9.6662 5.19564 9.71202L4.29704 7.19624L8.43329 7.95139C8.29802 8.14069 8.14752 8.31862 7.98329 8.48342L7.98236 8.48108Z"
        fill={fill}
      />
    </svg>
  );
}

import React, { useState, useEffect } from "react";
import ModalLayout from "../../../../common/ModalLayout";
import LoadingSpinner from "../../../../common/LoadingSpinner";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import {
  convertEnumToLabel,
  convertLabelToEnum,
  showErrorMessage,
  showToastMessage,
} from "../../../../../utils/helpers";
import axiosService from "../../../../../init/axios";

function AddDisputeModal({ tripId, tripDetails, closeModal, setRefresh }) {
  const [loading, setLoading] = useState(false);
  const [dropdownData, setDropdownData] = useState({
    disputeCategories: [],
    reasons: [],
    subReasons: [],
  });
  const [formData, setFormData] = useState({
    cancellationType: "",
    driverId: "",
    reason: "",
    subReason: "",
    disputeCategory: "",
  });
  const [amount, setAmount] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    setLoading(true);

    const fetchConfigData = async () => {
      try {
        const url =
          process.env.REACT_APP_DRIVER_BASE_URL +
          APIConfig.driverManagement.dropdownConfig(
            "DRIVER_CANCELLATION_PATHFINDER_DROPDOWNS"
          );
        const response = await axiosService.get(url);
        const addDriverCharge = response?.data?.dispute || [];
        setDropdownData({ ...dropdownData, fullData: addDriverCharge });
      } catch (error) {
        showErrorMessage(error);
      }
    };

    const fetchAmount = async () => {
      try {
        const url =
          process.env.REACT_APP_PRICING_BASE_URL +
          APIConfig.tripManagement.getCancellationCharge(
            tripDetails?.derivedTripType,
            tripDetails?.parentRegionId
          );
        const response = await axiosService.get(url);
        setAmount(response?.data?.vacConfig || "0");
      } catch (error) {
        console.error("Error fetching amount:", error);
      }
    };

    Promise.all([fetchConfigData(), fetchAmount()]).finally(() =>
      setLoading(false)
    );
  }, [tripDetails]);

  const handleChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));

    if (key === "cancellationType") {
      const selectedBeneficiaryData =
        dropdownData.fullData?.find((item) => item?.beneficiary === value) ||
        {};

      const disputeCategories =
        selectedBeneficiaryData?.disputeCategories?.map(
          (category) => category?.type
        ) || [];

      setDropdownData({
        fullData: dropdownData.fullData,
        disputeCategories,
        reasons: [],
        subReasons: [],
      });

      setFormData({
        cancellationType: value,
        disputeCategory: "",
        reason: "",
        subReason: "",
      });
    }

    if (key === "disputeCategory") {
      const selectedBeneficiaryData =
        dropdownData.fullData?.find(
          (item) => item?.beneficiary === formData?.cancellationType
        ) || {};

      const selectedCategory =
        selectedBeneficiaryData?.disputeCategories?.find(
          (category) => category?.type === value
        ) || {};

      const reasons =
        selectedCategory?.reasons?.map((reason) => reason?.reason) || [];

      setDropdownData((prevData) => ({
        ...prevData,
        reasons,
        subReasons: [],
      }));

      setFormData((prevData) => ({
        ...prevData,
        disputeCategory: value,
        reason: "",
        subReason: "",
      }));
    }

    if (key === "reason") {
      const selectedBeneficiaryData =
        dropdownData.fullData?.find(
          (item) => item?.beneficiary === formData?.cancellationType
        ) || {};

      const selectedCategory =
        selectedBeneficiaryData?.disputeCategories?.find(
          (category) => category?.type === formData?.disputeCategory
        ) || {};

      const selectedReason =
        selectedCategory?.reasons?.find((item) => item?.reason === value) || {};

      const subReasons = selectedReason?.subReason || [];

      setDropdownData((prevData) => ({
        ...prevData,
        subReasons,
      }));

      setFormData((prevData) => ({
        ...prevData,
        reason: value,
        subReason: "",
      }));
    }
  };

  const handleSubmit = () => {
    const { reason, subReason, disputeCategory } = formData;
    const formattingData = {
      tripId,
      disputeType: disputeCategory,
      requesterId:
        formData?.cancellationType === "DRIVER"
          ? formData?.driverId
          : tripDetails?.customerMobile,
      metadata: {
        reason,
        subReason,
      },
    };
    setLoading(true);
    const url =
      process.env.REACT_APP_TRIP_BASE_URL +
      APIConfig.tripManagement.addDispute(formData?.cancellationType);

    axiosService
      .post(url, formattingData)
      .then((e) => {
        if (e.data?.msg) {
          showToastMessage(e.data?.msg);
        } else {
          showToastMessage("Dispute raised successfully");
        }
        setRefresh((prevState) => (!prevState ? true : true));
        closeModal();
      })
      .catch((err) => showErrorMessage(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setIsSubmitDisabled(
      !formData?.cancellationType ||
        !formData?.disputeCategory ||
        !formData?.reason ||
        !formData?.subReason ||
        (formData?.cancellationType === "DRIVER" && !formData?.driverId)
    );
  }, [formData]);

  return (
    <ModalLayout title={"Add Dispute"} onClose={closeModal}>
      <div className="tw-w-[700px]">
        {loading ? (
          <div className="tw-flex tw-justify-center tw-items-center">
            <LoadingSpinner width={70} height={70} color="grey" />
          </div>
        ) : (
          <>
            <div className="tw-flex tw-gap-3 tw-flex-col">
              <div>
                <label className="tw-font-bold tw-text-sm">
                  Beneficiary Type
                </label>
                <div className="tw-flex tw-gap-8">
                  <label className="tw-flex tw-items-center tw-mr-8">
                    <input
                      type="radio"
                      name="cancellationType"
                      value="DRIVER"
                      checked={formData?.cancellationType === "DRIVER"}
                      onChange={(e) =>
                        handleChange("cancellationType", e.target.value)
                      }
                    />
                    <span className="tw-ml-2 tw-text-sm">Driver</span>
                  </label>
                  <label className="tw-flex tw-items-center tw-mr-8">
                    <input
                      type="radio"
                      name="cancellationType"
                      value="USER"
                      checked={formData?.cancellationType === "USER"}
                      onChange={(e) =>
                        handleChange("cancellationType", e.target.value)
                      }
                    />
                    <span className="tw-ml-2 tw-text-sm">Customer</span>
                  </label>
                </div>
              </div>

              <div className="tw-flex tw-gap-4">
                {formData?.cancellationType === "DRIVER" && (
                  <div className="tw-w-1/2">
                    <label className="tw-font-bold tw-text-sm">Driver Id</label>
                    <Input
                      type="text"
                      value={formData.driverId}
                      onChange={(e) => handleChange("driverId", e.target.value)}
                    />
                  </div>
                )}
              </div>

              <div className="tw-flex tw-gap-4">
                <div className="tw-w-1/2">
                  <label className="tw-font-bold tw-text-sm">
                    Dispute Category
                  </label>
                  <DropDownList
                    data={dropdownData?.disputeCategories?.map(item=> item && convertEnumToLabel(item)) || []}
                    value={convertEnumToLabel(formData.disputeCategory) || ""}
                    onChange={(e) =>
                      handleChange("disputeCategory", convertLabelToEnum(e.target.value) || "")
                    }
                  />
                </div>
                <div className="tw-w-1/2">
                  <label className="tw-font-bold tw-text-sm">Amount</label>
                  <Input
                    type="text"
                    value={
                      formData?.cancellationType === "DRIVER"
                        ? amount?.driverCancellationCharges
                        : formData?.cancellationType==="USER" ? amount?.cancellationCharges:""
                    }
                    disabled
                  />
                </div>
              </div>

              <div className="tw-flex tw-gap-4">
                <div className="tw-w-1/2">
                  <label className="tw-font-bold tw-text-sm">Reason</label>
                  <DropDownList
                    data={dropdownData.reasons}
                    value={formData.reason}
                    onChange={(e) => handleChange("reason", e.target.value)}
                  />
                </div>
                <div className="tw-w-1/2">
                  <label className="tw-font-bold tw-text-sm">Sub Reason</label>
                  <DropDownList
                    data={dropdownData.subReasons}
                    value={formData.subReason}
                    onChange={(e) => handleChange("subReason", e.target.value)}
                  />
                </div>
              </div>
            </div>
            <Button
              fillMode={"solid"}
              themeColor={"primary"}
              className="tw-mt-4 tw-px-6"
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
            >
              Submit
            </Button>
          </>
        )}
      </div>
    </ModalLayout>
  );
}

export default AddDisputeModal;

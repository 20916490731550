import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import React, { useEffect, useState, useCallback } from "react";
import { APIConfig } from "../../../utils/constants/api.constants";
import axiosService from "../../../init/axios";
import {
  CategoryFilterCell,
  CategoryFilterMultiSelectCell,
  convertEnumToLabel,
  timeConverter,
} from "../../../utils/helpers";
import { useParams } from "react-router-dom";
import GridLoader from "../../common/GridLoader";

function DriverChargesSection({
  refreshTable,
  setRefreshTable,
  handleModalOpener,
  setSelectedDriverRow,
}) {
  const { driverId } = useParams();
  const [driverCharges, setDriverCharges] = useState([]);
  const [dropdownData, setDropdownData] = useState({
    type: [],
    subType: [],
  });
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
  });
  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const dataReceived = (data) => {
    setRefreshTable((prev) => (!prev ? false : false));
    setDriverCharges({ data: data?.data, total: data?.total });
  };

  // Fetch dropdown data for configuration
  const fetchConfigData = useCallback(async () => {
    try {
      const url =
        process.env.REACT_APP_DRIVER_BASE_URL +
        APIConfig.driverManagement.dropdownConfig(
          "DRIVER_CANCELLATION_PATHFINDER_DROPDOWNS"
        );
      const response = await axiosService.get(url);

      const addDriverCharge = response?.data?.addDriverCharge || [];
      const formatType = addDriverCharge?.map((item) => ({
        value: item.type,
        label: item.type,
      }));

      const formatSubType = addDriverCharge.reduce((acc, item) => {
        const subTypes = item.subType || [];
        return [
          ...acc,
          ...subTypes.map((subType) => ({
            value: subType,
            label: subType,
          })),
        ];
      }, []); // Flatten subtypes and format as key-label pairs

      setDropdownData({
        type: formatType,
        subType: formatSubType,
      });
    } catch (error) {
      console.error("Dropdown Data Error:", error);
    }
  }, []);

  // Fetch driver charges
  const baseUrl =
    process.env.REACT_APP_DRIVER_BASE_URL +
    APIConfig.driverManagement.getAllDriverCharges(driverId);

  useEffect(() => {
    fetchConfigData();
  }, [fetchConfigData]);

  const columns = [
    {
      id: "chargeId",
      field: "chargeId",
      title: "Charged Id",
      width: 150,
    },
    {
      id: "tripId",
      field: "tripId",
      title: "Trip Id",
      filterable: true,
      filterKey: "trip_id",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 150,
    },
    {
      id: "createdAt",
      field: "createdAt",
      title: "Creation Date",
      width: 200,
      cell: (props) => (
        <td>{timeConverter(props?.dataItem?.createdAt) || "N.A."}</td>
      ),
    },
    {
      id: "type",
      field: "type",
      title: "Type",
      filterable: true,
      filterKey: "type",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: dropdownData?.type,
      width: 150,
      cell: (props) => (
        <td>{convertEnumToLabel(props?.dataItem?.type) || "N.A."}</td>
      ),
    },
    {
      id: "subType",
      field: "subType",
      title: "Sub Type",
      filterable: true,
      filterKey: "sub_type",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: dropdownData?.subType,
      width: 300,
      cell: (props) => (
        <td>{convertEnumToLabel(props?.dataItem?.subType) || "N.A."}</td>
      ),
    },
    {
      id: "amount",
      field: "amount",
      title: "Amount",
      width: 150,
    },
    {
      id: "status",
      field: "status",
      title: "Status",
      width: 150,
      cell: (props) => (
        <td>{convertEnumToLabel(props?.dataItem?.status) || "N.A."}</td>
      ),
    },
    {
      id: "history",
      field: "history",
      title: "History",
      width: 150,
      cell: (props) => (
        <td>
          <Button
            themeColor="info"
            fillMode="link"
            onClick={() => {
              handleModalOpener("historyModal");
              setSelectedDriverRow(props?.dataItem);
            }}
          >
            View
          </Button>
        </td>
      ),
    },
    {
      id: "recording_url",
      field: "recording_url",
      title: "Waive off",
      width: 150,
      cell: (props) => (
        <td>
          {props?.dataItem?.status === "APPLIED" ? (
          <Button
            themeColor="info"
            fillMode="link"
            onClick={() => {
              handleModalOpener("waiveOffModal");
              setSelectedDriverRow(props?.dataItem);
            }}
          >
            Waive Off
            </Button>) : null}
        </td>
      ),
    },
  ];

  return (
    <>
      <Grid
        data={driverCharges?.data?.data}
        filterable={true}
        onDataStateChange={dataStateChange}
        {...dataState}
        pageable={true}
      >
        {columns.map((column) => (
          <Column
            key={column?.id} // Unique key for each column
            field={column?.field}
            title={column?.title}
            filterable={column?.filterable || false}
            width={column?.width}
            cell={column?.cell || undefined} // Apply custom cell if defined
            filterCell={
              (column.defaultFilterOperator === "eq" &&
                ((props) => CategoryFilterCell(props, column))) ||
              (column.defaultFilterOperator === "eqIn" &&
                ((props) => CategoryFilterMultiSelectCell(props, column)))
            }
          />
        ))}
      </Grid>
      <GridLoader
        baseUrl={baseUrl}
        columns={columns}
        dataState={dataState}
        onDataReceived={dataReceived}
        refreshTable={refreshTable}
      />
    </>
  );
}

export default DriverChargesSection;

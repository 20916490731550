import React from "react";
import SidebarHamburger from "./SidebarHamburger";
import { useDispatch, useSelector } from "react-redux";

export default function SidebarWrapper({ children }) {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.component.sidebarToggle);
  return (
    <div
      className={`tw-relative tw-group tw-h-full ${
        !isOpen && "tw-pr-5 tw-bg-[var(--sidebar-background)]"
      }`}
      style={{
        borderRight: "1px solid var(--sidebar-border)",
      }}
    >
      <div
        className={`tw-absolute tw-bottom-20 tw-z-10 -tw-right-5 tw-duration-200 ${
          isOpen &&
          "tw-opacity-0 group-hover:tw-opacity-100 group-hover:tw-block"
        }`}
      >
        <SidebarHamburger dispatch={dispatch} isOpen={isOpen} />
      </div>
      {children}
    </div>
  );
}

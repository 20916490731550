import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Marker, Polyline } from "@react-google-maps/api";
import { mapDefaults } from "../../maps/defaults";
import GoogleMapsWrapper from "../../maps/GoogleMapsWrapper";
import startPointSymbol from "../../../assets/start-point-2.png";
import endPointSymbol from "../../../assets/end-point-2.png";

const TripDetailsMap = ({ tripDetails }) => {
  const containerStyle = { width: "100%", height: "100%" };
  const mapRef = useRef(null);

  const pickup =
    tripDetails?.actualSourceLocation || tripDetails?.expectedSourceLocation;
  const drop =
    tripDetails?.actualDestinationLocation ||
    tripDetails?.expectedDestinationLocation;

  const pitstops = tripDetails?.stops?.stops || [];

  const center = useMemo(
    () => ({
      lat: (pickup?.lat + drop?.lat) / 2,
      lng: (pickup?.lng + drop?.lng) / 2,
    }),
    [pickup, drop]
  );

  const onLoad = useCallback(
    (map) => {
      mapRef.current = map;
      if (mapRef.current && pickup && drop) {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(pickup);
        bounds.extend(drop);
        pitstops.forEach((stop) => bounds.extend(stop));
        mapRef.current.fitBounds(bounds);
      }
    },
    [pickup, drop, pitstops]
  );

  const options = useMemo(() => mapDefaults.fleetViewMap.options, []);
  const path = [
    { lat: pickup?.lat, lng: pickup?.lng },
    ...pitstops.map((item) => ({ lat: item?.lat, lng: item?.lng })),
    { lat: drop?.lat, lng: drop?.lng },
  ];

  return (
    <div className="tw-bg-black tw-col-span-2">
      <GoogleMapsWrapper
        containerStyle={containerStyle}
        options={options}
        center={center}
        zoom={mapDefaults.tripDetails.zoom}
        onLoad={onLoad}
        mapRef={mapRef.current}
      >
        {mapRef.current && (
          <>
            <Marker
              position={pickup}
              icon={{
                url: startPointSymbol,
                scaledSize: new window.google.maps.Size(30, 30),
              }}
            />
            <Marker
              position={drop}
              icon={{
                url: endPointSymbol,
                scaledSize: new window.google.maps.Size(30, 30),
              }}
            />
            {pitstops?.map((item, index) => (
              <Marker
                key={index}
                position={{ lat: item?.lat, lng: item?.lng }}
                // label={`S${index + 1}`}
                label={{
                  text: `S${index + 1}`,
                  color: "white",
                  fontSize: "12px",
                }}
                icon={{
                  url: `data:image/svg+xml;utf-8,
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
                    <circle cx="20" cy="20" r="18" fill="black" stroke="white" stroke-width="2"/>
                    </svg>`,
                  scaledSize: new window.google.maps.Size(40, 40), // Adjust size of the marker
                }}
              />
            ))}
            <Polyline
              path={path}
              options={{
                strokeColor: "#000",
                strokeOpacity: 0,
                icons: [
                  {
                    icon: {
                      path: "M 0,-1 0,1",
                      strokeOpacity: 1,
                      scale: 4,
                    },
                    offset: "0",
                    repeat: "20px",
                  },
                ],
                strokeWeight: 2,
              }}
            />
          </>
        )}
      </GoogleMapsWrapper>
    </div>
  );
};

export default TripDetailsMap;

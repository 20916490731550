import React, { Fragment, useEffect, useState } from "react";
import CardLayout from "../../components/common/CardLayout";
import TripManagementTable from "../../components/trip-management/TripManagementTable";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import TripDetailPage from "../../components/trip-management/trip-details/TripDetailPage";
import { useDispatch, useSelector } from "react-redux";
import { updateTripRegionFilter } from "../../slices/regionSlice";
import {
  formatDate,
  getFromLocalStorage,
  toDateString,
} from "../../utils/helpers";
import { DropdownSearchMultiSelect } from "../../utils/forms/NewFormComponents";
import SideSheetController from "./SideSheetController";

const TripManagement = () => {
  const useQueryParams = () => {
    const [searchParams] = useSearchParams();
    return Object?.fromEntries([...searchParams]);
  };
  const queryParams = useQueryParams();
  const { date, clientType, clientName, selectedAll } = queryParams;

  const tripManagementQueryParams = useSelector(
    (state) => state.component.tripManagementQueryParams
  );

  const location = useLocation();
  const { tripId } = useParams();
  const clientDetails = useSelector((state) => state.auth?.clientDetails);
  const [tripDetailsSelected, setTripDetailsSelected] = useState(false);
  const [statusFilter, setStatusFilter] = useState("All");
  const regions = useSelector((state) => state.region?.labelRegions);
  const selectedTripRegionFilters = useSelector((state) => state.region?.trip);
  const dispatch = useDispatch();
  const calendarDate = date || toDateString(new Date());
  const calendarToggle = date ? true : false;
  const navigate = useNavigate();
  const [dataState, setDataState] = useState(() => {
    const regionFilters = getFromLocalStorage("regionFilters");
    const regionTrips = regionFilters?.trip ?? [];

    // Prepare region filters if available
    const labelRegionId =
      regionTrips.length > 0
        ? regionTrips.map((filter) => filter.value).join(",") + ":in:string"
        : null;

    // Prepare other parameters
    const otherParams = {
      ...(labelRegionId && { label_region_id: labelRegionId }),
      ...(calendarToggle && {
        expected_trip_start_time: `${calendarDate}:eq:date`,
      }),
      ...(clientName && { client_name: `${clientName}:in:string` }),
      ...(clientType && { client_type: `${clientType}:eq:string` }),
      scenario: "AP_PANEL_HIDE_B2C_SUBS",
    };

    return {
      take: 10,
      skip: 0,
      otherParams,
    };
  });

  useEffect(() => {
    navigate(tripManagementQueryParams);
    setStatusFilter("All");
  }, [tripManagementQueryParams]);

  useEffect(() => {
    if (tripId && location.pathname.split("/").at(-1) === "details") {
      setTripDetailsSelected(true);
    } else {
      setTripDetailsSelected(false);
    }
  }, [location]);

  useEffect(() => {
    const regionFilters = getFromLocalStorage("regionFilters");
    const regionTrips = regionFilters?.trip ?? [];

    // Prepare region filters if available
    const labelRegionId =
      regionTrips.length > 0
        ? regionTrips.map((filter) => filter.value).join(",") + ":in:string"
        : null;
    setDataState({
      ...dataState,
      take: 10,
      skip: 0,
      otherParams: {
        ...(labelRegionId && { label_region_id: labelRegionId }),

        ...(calendarToggle && {
          expected_trip_start_time: calendarDate + ":eq:date",
        }),
        scenario: "AP_PANEL_HIDE_B2C_SUBS",
        ...(clientName && { client_name: clientName + ":in:string" }),
        ...(clientType && { client_type: clientType + ":eq:string" }),
      },
    });
  }, [date, clientType, clientName, selectedAll]);

  const onTripRegionFilterChange = (e) => {
    dispatch(updateTripRegionFilter([...e]));
    let otherParams = {
      ...(calendarToggle && {
        expected_trip_start_time: calendarDate + ":eq:date",
      }),
      scenario: "AP_PANEL_HIDE_B2C_SUBS",
      ...(clientName && { client_name: clientName + ":in:string" }),
      ...(clientType && { client_type: clientType + ":eq:string" }),
    };
    if (statusFilter?.filters?.length > 0) {
      const [key, value] = statusFilter.filters[0].split("=");
      otherParams[key] = value;
    }
    if (e.length > 0) {
      const regionQueryStr = e?.reduce(
        (acc, curr) => (acc += curr?.value + ","),
        ""
      );

      otherParams["label_region_id"] =
        regionQueryStr.slice(0, -1) + ":in:string";

      setDataState({
        ...dataState,
        skip: 0,
        take: 10,
        otherParams: otherParams,
      });
    } else {
      if (
        dataState?.otherParams &&
        Object.keys(dataState?.otherParams)?.length > 0
      ) {
        setDataState({
          ...dataState,
          skip: 0,
          take: 10,
          otherParams: otherParams,
        });
      }
    }
  };

  const updatedRegions = regions
    ?.slice()
    ?.map((item) => {
      const isSelected = selectedTripRegionFilters.some(
        (selectedItem) => selectedItem.regionId === item.regionId
      );

      if (isSelected) {
        return null;
      }

      return {
        ...item,
        label: item.child
          ? `${item.regionName} (${item.parentRegionName})`
          : item.regionName,
      };
    })
    .filter((item) => item !== null);

  const TripTopBar = () => (
    <div
      className={`${
        !clientDetails?.clientId ? "tw-justify-between" : "tw-justify-end"
      } tw-flex tw-items-center`}
    >
      {!clientDetails?.clientId && (
        <DropdownSearchMultiSelect
          options={updatedRegions}
          textField="label"
          values={selectedTripRegionFilters}
          onChange={onTripRegionFilterChange}
        />
      )}
      <SideSheetController />
    </div>
  );

  const isTripDetailsPage =
    tripId && location.pathname.split("/").at(-1) === "details";

  return (
    <>
      {isTripDetailsPage ? (
        <TripDetailPage setTripDetailsSelected={setTripDetailsSelected} />
      ) : (
        <CardLayout title="Trip Management" ComponentRender={TripTopBar}>
          <TripManagementTable
            calendarToggle={calendarToggle}
            calendarDate={calendarDate}
            setStatusFilter={setStatusFilter}
            statusFilter={statusFilter}
            setTripDetailsSelected={setTripDetailsSelected}
            dataState={dataState}
            setDataState={setDataState}
          />
        </CardLayout>
      )}
    </>
  );
};

export default TripManagement;

import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../../common/LoadingSpinner";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import axiosService from "../../../../../init/axios";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import {
  convertEnumToLabel,
  showErrorMessage,
  timeConverter,
} from "../../../../../utils/helpers";
import { Button } from "@progress/kendo-react-buttons";
import GridLoader from "../../../../common/GridLoader";

function DisputeLogs({ tripId,refresh, setRefresh, handleModalOpener }) {
  const [loading, setLoading] = useState(false);
  const [disputeLogs, setDisputeLogs] = useState([]);
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
  });
  const baseUrl =
    process.env.REACT_APP_TRIP_BASE_URL +
    APIConfig.tripManagement.getDisputeLogsData(tripId);
  // const fetchDisputeLogs = async () => {
  //   setLoading(true);
  //   try {
  //     const { data } = await axiosService.get(
  //       baseUrl
  //     );
  //     if (data) setDisputeLogs(data);
  //   } catch (error) {
  //     showErrorMessage(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const dataReceived = (dataObj) => {
    setDisputeLogs(dataObj.data);
    setRefresh(prevState=>!prevState?false:false);
  };

  // useEffect(() => {
  //   fetchDisputeLogs();
  // }, []);

  const createCellRenderer =
    (extractor = (data) => data, defaultValue = "--") =>
    (props) => {
      const value = extractor(props?.dataItem);
      return <td>{value || defaultValue}</td>;
    };

  // Column definitions
  const columns = [
    {
      id: "id",
      field: "disputeId",
      title: "Dispute Id",
      width: 150,
    },
    {
      id: "natureOfDispute",
      field: "disputeType",
      title: "Nature of Dispute",
      width: 250,
      cell: createCellRenderer((data) => convertEnumToLabel(data?.disputeType)),
    },
    {
      id: "refundMode",
      field: "metadata.refundMode",
      title: "Refund Mode",
      width: 150,
      cell: createCellRenderer((data) =>
        convertEnumToLabel(data?.metadata?.refundMode)
      ),
    },
    {
      id: "createdAt",
      field: "createdAt",
      title: "Created At",
      width: 250,
      cell: createCellRenderer((data) => timeConverter(data?.createdAt)),
    },
    {
      id: "updatedAt",
      field: "updatedAt",
      title: "Updated At",
      width: 250,
      cell: createCellRenderer((data) => timeConverter(data?.updatedAt)),
    },
    {
      id: "updatedBy",
      field: "updatedBy",
      title: "Updated By",
      width: 150,
      cell: createCellRenderer((data) => convertEnumToLabel(data?.updatedBy)),
    },
    {
      id: "status",
      field: "action",
      title: "Status",
      width: 150,
      cell: createCellRenderer((data) => convertEnumToLabel(data?.action)),
    },
    {
      id: "amount",
      field: "metadata.amount",
      title: "Amount",
      width: 150,
      cell: createCellRenderer((data) => data?.metadata?.amount),
    },
  ];

  return (
    <>
      <Grid data={disputeLogs}>
        {columns.map((column) => (
          <Column
            key={column.id}
            field={column.field}
            title={column.title}
            width={column.width}
            cell={column.cell}
          />
        ))}
      </Grid>
      <GridLoader
        baseUrl={baseUrl}
        refreshTable={refresh}
        columns={columns}
        dataState={dataState}
        onDataReceived={dataReceived}
      />
      <Button
        fillMode={"solid"}
        themeColor={"primary"}
        className="mt-3"
        onClick={() => handleModalOpener("addDisputeModal")}
      >
        Add Dispute
      </Button>
    </>
  );
}

export default DisputeLogs;

import React, { useEffect, useState } from "react";
import {
  getLabelFromEnum,
  replaceNullValues,
  showErrorMessage,
  stringToSentenceCase,
  showToastMessage,
  convertEnumToLabel,
  timeConverter,
  getImgSrc,
} from "../../utils/helpers";
import axiosService from "../../init/axios";
import { APIConfig } from "../../utils/constants/api.constants";
import TripDetailsMap from "../../components/trip-management/trip-details/TripDetailsMap";
import { Button } from "@progress/kendo-react-buttons";
import ModalLayout from "../../components/common/ModalLayout";
import LoadingSpinner from "../../components/common/LoadingSpinner";

export default function B2BTripManagementPage({ tripId, tripDetails }) {
  const [updatedTripDetails, setUpdatedTripDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [checklistData, setChecklistData] = useState({
    DROP: "",
    PICKUP: "",
    tripID: tripId,
  });
  useEffect(() => {
    const updatedDetails = getLabelFromEnum(
      ["derivedTripType", "journeyMode", "stage", "tripBookingMode"],
      { ...tripDetails },
      "trip"
    );
    setUpdatedTripDetails(updatedDetails);
    getChecklistData();
    getChecklistDetails();
  }, []);
  const fetchChecklist = async (checklistType, fetchDetails) => {
    if (!tripDetails?.checklist) return;

    const checklistId = tripDetails?.checklist?.[checklistType];
    if (checklistId) {
      const url =
        process.env.REACT_APP_TRIP_BASE_URL +
        APIConfig.tripManagement[fetchDetails](tripId, checklistId);

      await axiosService.get(url).then((response) => {
        const checklistKey =
          response?.data?.checklistType || response?.data?.checkListType;

        if (checklistKey) {
          setChecklistData((prev) => ({
            ...prev,
            [checklistKey]: {
              ...prev[checklistKey],
              ...response.data,
            },
          }));
        }
      });
    }
  };

  const getChecklistDetails = async () => {
    await fetchChecklist("PICKUP", "getChecklistDetails");
    await fetchChecklist("DROP", "getChecklistDetails");
  };

  const getChecklistData = async () => {
    await fetchChecklist("PICKUP", "getChecklistData");
    await fetchChecklist("DROP", "getChecklistData");
  };

  const markDriverNotFound = async () => {
    setLoading(true);
    await axiosService
      .put(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.markDnf(tripId)
      )
      .then((data) => {
        setLoading(false);
        showToastMessage("Marked DNF successfully");
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  return (
    <>
      <div
        style={{
          marginBottom: "32px",
          fontSize: "14px",
        }}
        className="tw-grid tw-gap-5 tw-grid-cols-6"
      >
        <div className="tw-col-span-4 tw-grid tw-grid-cols-2 tw-gap-5">
          {/* DRIVER DETAILS */}
          <div>
            <h5 className="tw-text-[16px]">Driver Details</h5>
            <div className="custom-border-primary tw-p-4 tw-rounded-md">
              <div className="tw-flex tw-justify-between">
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                    Driver Name
                  </p>
                  <p className="tw-mb-0">
                    {replaceNullValues(
                      stringToSentenceCase(
                        updatedTripDetails?.driverDetails?.fullName
                      )
                    )}
                  </p>
                </div>
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                    Driver contact number
                  </p>
                  <p className="tw-mb-0">
                    {replaceNullValues(
                      stringToSentenceCase(tripDetails?.driverMobile)
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h5 className="tw-text-[16px]">Vehicle Details</h5>
            <div className="custom-border-primary tw-p-4 tw-rounded-md">
              <div className="tw-flex tw-justify-between">
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                    Car type:
                  </p>
                  <p className="tw-mb-0">
                    {convertEnumToLabel(tripDetails?.vehicleDetails?.carType) ||
                      "N.A"}
                  </p>
                </div>
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                    Fuel type:
                  </p>
                  <p className="tw-mb-0">
                    {convertEnumToLabel(
                      tripDetails?.vehicleDetails?.fuelType
                    ) || "N.A"}
                  </p>
                </div>
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                    Transmission type:
                  </p>
                  <p className="tw-mb-0">
                    {convertEnumToLabel(
                      tripDetails?.vehicleDetails?.transmissionType
                    ) || "N.A"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-col-span-2">
            <h5 className="tw-text-[16px]">Trip Details</h5>
            <div className="custom-border-primary tw-p-4 tw-rounded-md">
              <div className="tw-grid tw-grid-cols-3">
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                    Trip type:
                  </p>
                  <p className="tw-mb-0">
                    {convertEnumToLabel(tripDetails?.tripType)}
                  </p>
                </div>
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                    Trip sub type:
                  </p>
                  <p className="tw-mb-0">
                    {convertEnumToLabel(tripDetails?.journeyMode)}
                  </p>
                </div>
                <div>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                    Trip Booking Mode:
                  </p>
                  <p className="tw-mb-0">
                    {convertEnumToLabel(tripDetails?.tripBookingMode)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* TRIP DETAILS */}
          <div className="tw-grid tw-grid-cols-2 tw-gap-5 tw-col-span-2">
            <DetailsSection
              qcLink={tripDetails?.checklistParams?.PICKUP?.qcUrl || null}
              title="Pickup Details"
              customerDetails={
                tripDetails?.clientCustomerDetails?.PICKUP?.customerDetails
              }
              pocDetails={
                tripDetails?.clientCustomerDetails?.PICKUP?.pocDetails
              }
              address={
                tripDetails?.actualSourceLocation?.heading
                  ? tripDetails?.actualSourceLocation?.heading +
                    (tripDetails?.actualSourceLocation?.subHeading
                      ? ", " + tripDetails?.actualSourceLocation?.subHeading
                      : "")
                  : replaceNullValues(
                      tripDetails?.expectedSourceLocation?.heading
                        ? tripDetails?.expectedSourceLocation?.heading +
                            (tripDetails?.expectedSourceLocation?.subHeading
                              ? ", " +
                                tripDetails?.expectedSourceLocation?.subHeading
                              : "")
                        : tripDetails?.expectedSourceLocation?.formattedAddress
                    )
              }
              vehicleType={tripDetails?.vehicleDetails?.carType}
              tripStartTime={timeConverter(tripDetails?.expectedTripStartTime)}
              actualTripStartTime={timeConverter(
                tripDetails?.actualTripStartTime
              )}
              checklist={checklistData.PICKUP}
              tripID={checklistData.tripID}
            />
            <DetailsSection
              qcLink={tripDetails?.checklistParams?.DROP?.qcUrl || null}
              title="Drop Details"
              customerDetails={
                tripDetails?.clientCustomerDetails?.DROP?.customerDetails
              }
              pocDetails={tripDetails?.clientCustomerDetails?.DROP?.pocDetails}
              address={
                tripDetails?.actualDestinationLocation?.heading
                  ? tripDetails?.actualDestinationLocation?.heading +
                    (tripDetails?.actualDestinationLocation?.subHeading
                      ? ", " +
                        tripDetails?.actualDestinationLocation?.subHeading
                      : "")
                  : replaceNullValues(
                      tripDetails?.expectedDestinationLocation?.heading
                        ? tripDetails?.expectedDestinationLocation?.heading +
                            (tripDetails?.expectedDestinationLocation
                              ?.subHeading
                              ? ", " +
                                tripDetails?.expectedDestinationLocation
                                  ?.subHeading
                              : "")
                        : tripDetails?.expectedDestinationLocation
                            ?.formattedAddress
                    )
              }
              actualTripEndTime={timeConverter(tripDetails?.actualTripEndTime)}
              tripEndTime={timeConverter(tripDetails?.expectedTripEndTime)}
              checklist={checklistData.DROP}
              tripID={checklistData.tripID}
            />
          </div>
        </div>

        {tripDetails && <TripDetailsMap tripDetails={tripDetails} />}
      </div>
    </>
  );
}

const DetailsSection = ({
  qcLink,
  title,
  customerDetails,
  address,
  tripStartTime,
  tripEndTime,
  pocDetails,
  checklist,
  tripID,
  actualTripEndTime,
  actualTripStartTime,
}) => {
  const { checkListId, clientTasks, taskSubmissionList } = checklist;

  const otpBypassAllowed = clientTasks?.find((item) => item.formType === "OTP");
  const otp = clientTasks?.find((item) => item.formType === "OTP");

  const images = taskSubmissionList?.find(
    (item) => item.taskType === "UPLOAD_IMAGE"
  );
  // const qcLink = taskSubmissionList?.find(
  //   (item) => item.taskType === "DRIVER_QC"
  // );
  const handleOverrideOTP = async () => {
    const otpTaskID = otp?.id;

    const baseUrl =
      process.env.REACT_APP_TRIP_BASE_URL +
      APIConfig.tripManagement.overrideOTP(tripID, checkListId, otpTaskID);

    await axiosService
      .put(baseUrl)
      .then((e) => {
        showToastMessage("Override OTP Successful");
      })
      .catch((e) => showErrorMessage(e));
  };

  return (
    <div className="">
      <h5 className="tw-text-[16px]">{title}</h5>
      <div className="custom-border-primary tw-space-y-2 tw-p-4 tw-rounded-md">
        <div className="tw-flex tw-gap-5">
          <div className="tw-w-1/2">
            <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
              Customer name:
            </p>
            <p className="tw-mb-0">{customerDetails?.name || "N.A."}</p>
          </div>
          <div className="tw-w-1/2">
            <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
              Customer Phone:
            </p>
            <p className="tw-mb-0">{customerDetails?.phoneNumber}</p>
          </div>
        </div>
        <div className="tw-flex tw-gap-5">
          <div className="tw-w-1/2">
            <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">POC Name:</p>
            <p className="tw-mb-0">{pocDetails?.name || "N.A."}</p>
          </div>
          <div className="tw-w-1/2">
            <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">POC Phone:</p>
            <p className="tw-mb-0">{pocDetails?.phoneNumber}</p>
          </div>
        </div>
        <div className="tw-flex tw-gap-5">
          <div>
            <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">Address:</p>
            <p className="tw-mb-0">{address}</p>
          </div>
        </div>
        <div className="tw-flex tw-gap-5 tw-items-end">
          <div className="tw-w-1/2">
            {actualTripStartTime ? (
              <>
                <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                  Trip start time:
                </p>
                <p className="tw-mb-0">{actualTripStartTime}</p>
              </>
            ) : (
              <>
                {tripStartTime && (
                  <>
                    <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                      Expected trip start time:
                    </p>
                    <p className="tw-mb-0">{tripStartTime}</p>
                  </>
                )}
              </>
            )}
            {actualTripEndTime ? (
              <>
                <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                  Trip end time:
                </p>
                <p className="tw-mb-0">{actualTripEndTime}</p>
              </>
            ) : (
              <>
                {tripEndTime && (
                  <>
                    <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                      Expected trip end time:
                    </p>
                    <p className="tw-mb-0">{tripEndTime}</p>
                  </>
                )}
              </>
            )}
          </div>
          {otpBypassAllowed && (
            <div className="tw-w-1/2">
              {otp?.status === "COMPLETED" && (
                <>
                  <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                    OTP:
                    <span className="status-pill tw-ml-2 out_of_serviceable_area tw-capitalize">
                      {otp?.status?.toLowerCase()}
                    </span>
                  </p>
                </>
              )}
              {otpBypassAllowed.task.bypassable && (
                <Button onClick={handleOverrideOTP} themeColor={null}>
                  Override OTP
                </Button>
              )}
            </div>
          )}
        </div>

        {qcLink && (
          <div className="tw-flex tw-gap-5">
            <div>
              <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                Driver QC Link:
              </p>
              <a href={qcLink} target="_blank">
                QC Link
              </a>
            </div>
          </div>
        )}

        {images && (
          <div className="tw-flex tw-gap-5">
            <div>
              <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">Images:</p>
              <ImageHandler images={images} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ImageHandler = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleClick = async (imageKey) => {
    setLoading(true);
    await getImgSrc(images.data[imageKey]).then((e) => {
      setSelectedImage(e);
      setLoading(false);
    });
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      {Object.keys(images?.data).map((key) => (
        <span
          onClick={() => handleClick(key)}
          key={key}
          className="tw-mb-2 tw-inline-block tw-mr-2 tw-cursor-pointer"
        >
          {key}
        </span>
      ))}
      {loading ? (
        <div className="tw-fixed tw-top-0 tw-inset-0 tw-grid tw-place-content-center tw-bg-black/50 tw-backdrop-blur-sm tw-z-[10]">
          <LoadingSpinner color="#eee" />
        </div>
      ) : (
        <>
          {selectedImage && (
            <ModalLayout
              title="Image Preview"
              onClose={handleClose}
              parentClasses=""
            >
              <div
                style={{
                  width: "500px",
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "700",
                  position: "relative",
                }}
              >
                <img
                  src={selectedImage}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </ModalLayout>
          )}
        </>
      )}
    </div>
  );
};

import React, { useCallback } from "react";

const ButtonGroup = ({
  buttons,
  activeValue,
  onActiveChange,
  fontSize = "xs",
  className = "",
}) => {
  const handleButtonClick = useCallback(
    (buttonValue) => {
      onActiveChange(buttonValue);
    },
    [onActiveChange]
  );

  const fontSizeStyles = {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
  };
  
  return (
    <div
      className={`border border-gray-300 tw-rounded-lg tw-p-1 tw-inline-flex ${className}`}
    >
      {buttons.map((button) => (
        <button
          key={button?.value}
          onClick={() => handleButtonClick(button)}
          className={`tw-px-3 tw-py-1 tw-rounded-md ${
            activeValue?.value === button?.value
              ? "tw-bg-gray-200 tw-text-black"
              : "tw-bg-transparent tw-text-gray-500"
          } 
          tw-focus:outline-none tw-border-none tw-transition-colors tw-duration-200`}
          style={{ fontSize: fontSizeStyles[fontSize] || fontSizeStyles.sm }}
        > 
          {button?.label}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;

import React, { useEffect, useState } from "react";
import Sidesheet from "../../components/common/Sidesheet";
import {
  convertClientsArray,
  formatDate,
  showErrorMessage,
  toDateString,
} from "../../utils/helpers";
import ToggleSwitch from "../../components/common/Components";
import { DropdownSearchMultiSelect } from "../../utils/forms/NewFormComponents";
import { APIConfig } from "../../utils/constants/api.constants";
import axiosService from "../../init/axios";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { useNavigate, useSearchParams } from "react-router-dom";
import FiltersIcon from "../../assets/icons/FiltersIcon";
import { useDispatch, useSelector } from "react-redux";
import { updateTripManagementQueryParams } from "../../slices/componentStatesSlice";
import LoadingPanel from "../../components/common/LoadingPanel";

const clientTypeOptions = [
  { label: "All", value: "all" },
  { label: "B2B", value: "B2B" },
  { label: "B2C", value: "B2C" },
];

const getSelectedClients = (clientName, newClients) => {
  if (!clientName) return [];
  const namesArray = clientName?.split(",").map((name) => name?.trim());
  const filteredClients = newClients?.filter((client) =>
    namesArray?.includes(client?.clientName)
  );

  return filteredClients;
};

const FiltersLabel = ({ count = 0 }) => (
  <>
    <FiltersIcon />
    Filter
    <span
      style={{ border: "1px solid #2756B3", borderRadius: "50%" }}
      className="tw-h-5 tw-text-xs tw-grid tw-place-content-center tw-w-5"
    >
      {count}
    </span>
  </>
);

const countFilters = (params) => {
  return (
    Object?.values(params)?.filter(
      (value) => value !== undefined && value !== ""
    )?.length || 0
  );
};

export default function SideSheetController() {
  const [loading, setLoading] = useState(false);
  const clientId = useSelector((state) => state.auth?.clientId);

  const useQueryParams = () => {
    const [searchParams] = useSearchParams();
    return Object.fromEntries([...searchParams]);
  };
  const dispatch = useDispatch();
  const queryParams = useQueryParams();

  const {
    date,
    clientType,
    clientName,
    selectAll: selectAllBool,
  } = queryParams;

  const stateDefaults = {
    showSheet: false,
    calendarDate: date || toDateString(new Date()),
    calendarToggle: date ? true : false,
    expanded: [""],
    selectedClientType: clientType ? clientType : "all",
    clients: [],
    selectedClients: [],
    selectAll: selectAllBool || false,
  };

  const handleCloseSheet = () => {
    setShowSheet(stateDefaults.showSheet);
    setCalendarDate(stateDefaults.calendarDate);
    setCalendarToggle(stateDefaults.calendarToggle);
    setExpanded(stateDefaults.expanded);
    setSelectedClientType(stateDefaults.selectedClientType);
    setClients(stateDefaults.clients);
    setSelectedClients(stateDefaults.selectedClients);
    setSelectAll(stateDefaults.selectAll);
  };

  const [showSheet, setShowSheet] = useState(stateDefaults.showSheet);

  const [calendarDate, setCalendarDate] = useState(stateDefaults.calendarDate);

  const [calendarToggle, setCalendarToggle] = useState(
    stateDefaults.calendarToggle
  );

  const [expanded, setExpanded] = useState(stateDefaults.expanded);

  const [selectedClientType, setSelectedClientType] = useState(
    stateDefaults.selectedClientType
  );

  const [clients, setClients] = useState(stateDefaults.clients);

  const [selectedClients, setSelectedClients] = useState(
    stateDefaults.selectedClients
  );

  const [selectAll, setSelectAll] = useState(stateDefaults.selectAll);

  const handleToggleCalendar = () => setCalendarToggle((prev) => !prev);
  const handleDateChange = (e) => setCalendarDate(e.target.value);
  const handleClientTypeChange = (e) => {
    setSelectedClients([]);
    setSelectAll(false);
    setSelectedClientType(e.target.value);
  };

  const getClients = async (setLoading) => {
    setLoading(true);
    const baseUrl =
      process.env.REACT_APP_USER_BASE_URL + APIConfig.clients.getClients;

    try {
      const { data } = await axiosService.get(baseUrl);
      const newClients = convertClientsArray(data.clients);
      setClients(newClients);

      const preselectedClients = getSelectedClients(clientName, newClients);
      setSelectedClients(selectAll ? newClients : preselectedClients);
      setLoading(false);
    } catch (error) {
      showErrorMessage(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!clientId) {
      if (showSheet) {
        getClients(setLoading);
      } else {
        setClients([]);
      }
    }
  }, [showSheet]);

  const updatedClients = clients
    ?.slice()
    ?.map((item) => {
      const isSelected = selectedClients.some(
        (selectedItem) => selectedItem.value === item.value
      );

      if (isSelected) {
        return null;
      }

      return {
        ...item,
      };
    })
    .filter((item) => item !== null);

  const handleClientSelection = (clientValue) => {
    const isAlreadySelected = selectedClients?.some(
      (client) => client?.value === clientValue
    );

    let updatedSelectedClients;

    if (isAlreadySelected) {
      updatedSelectedClients = selectedClients.filter(
        (client) => client?.value !== clientValue
      );
    } else {
      const selectedClient = clients?.find(
        (client) => client?.value === clientValue
      );
      updatedSelectedClients = [...selectedClients, selectedClient];
    }

    setSelectedClients(updatedSelectedClients);

    setSelectAll(updatedSelectedClients?.length === clients?.length);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedClients([]);
    } else {
      setSelectedClients(clients);
    }
    setSelectAll(!selectAll);
  };

  const handleClientNameChange = (e) => {
    setSelectedClients(e);
    setSelectAll(e.length === clients.length);
  };

  const handleSelect = (event) => {
    if (event.expandedItems) {
      setExpanded(event.expandedItems);
    }
  };

  const handleRemoveSelectedClient = (clientValue) => {
    const updatedSelectedClients = selectedClients.filter(
      (client) => client.value !== clientValue
    );
    setSelectedClients(updatedSelectedClients);
    setSelectAll(false);
  };

  const handleReset = (e) => {
    e.preventDefault();
    dispatch(updateTripManagementQueryParams(""));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const queryParams = {};
    if (calendarToggle) {
      queryParams.date = calendarDate;
    }
    if (selectedClientType !== "all") {
      queryParams.clientType = selectedClientType;
    }
    if (selectAll) {
      queryParams.selectAll = true;
    } else if (selectedClients.length > 0) {
      queryParams.clientName = selectedClients
        .map((client) => client?.label)
        .join(",");
    }

    const queryString = new URLSearchParams(queryParams)?.toString();
    dispatch(updateTripManagementQueryParams(`?${queryString}`));
  };

  return (
    <div className="tw-justify-end tw-flex tw-items-center">
      <Sidesheet
        buttonStyles={{
          backgroundColor: "white",
          color: "#2756B3",
        }}
        handleCloseSheet={handleCloseSheet}
        heading="Filters"
        handleShowSheet={() => setShowSheet(true)}
        Label={<FiltersLabel count={countFilters(queryParams)} />}
        showSheet={showSheet}
        setShowSheet={setShowSheet}
      >
        <form onSubmit={(e) => e.preventDefault()}>
          <FilterSection title="Date">
            <div className="tw-flex tw-items-center tw-justify-between">
              <HandleDateComponent
                calendarToggle={calendarToggle}
                calendarDate={calendarDate}
                handleDateChange={handleDateChange}
              />
              <ToggleSwitch
                type="checkbox"
                checked={calendarToggle}
                onChange={handleToggleCalendar}
              />
            </div>
          </FilterSection>

          {!clientId && (
            <>
              <FilterSection title="Client type">
                <div className="tw-grid tw-grid-cols-3">
                  {clientTypeOptions.map((item) => (
                    <RadioInput
                      key={item.value}
                      id={item.label}
                      name="clientType"
                      value={item.value}
                      checked={selectedClientType === item.value}
                      onChange={handleClientTypeChange}
                      label={item.label}
                    />
                  ))}
                </div>
              </FilterSection>

              <FilterSection title="Client name">
                <div className="tw-flex tw-gap-5">
                  <DropdownSearchMultiSelect
                    disabled={selectedClientType != "B2B"}
                    options={updatedClients}
                    textField="label"
                    values={selectedClients}
                    onChange={handleClientNameChange}
                    backupMessage="No Options Available"
                    parentClass="tw-relative tw-w-full"
                    showValues={false}
                  />
                  <CheckboxInput
                    disabled={selectedClientType != "B2B"}
                    id="selectAll"
                    name="selectAll"
                    value="selectAll"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    label="Select All"
                  />
                </div>
              </FilterSection>

              <FilterSection title="">
                <PanelBar
                  expanded={expanded}
                  onSelect={handleSelect}
                  expandMode={"single"}
                >
                  <PanelBarItem
                    key="candidate-drivers"
                    title={`Selected Clients ${
                      selectedClients?.length > 0
                        ? `(${selectedClients?.length})`
                        : ""
                    }`}
                  >
                    {selectedClients?.length > 0 && (
                      <div className="tw-flex tw-items-center tw-gap-3 tw-flex-wrap">
                        {selectedClients?.map((client) => (
                          <span
                            key={client?.value}
                            className="tw-flex tw-text-sm hover:tw-bg-black/5 tw-select-none tw-duration-300 tw-items-center tw-rounded-md tw-cursor-pointer tw-bg-white tw-text-borderPrimary border tw-px-3 tw-py-1"
                            onClick={() =>
                              handleRemoveSelectedClient(client?.value)
                            }
                          >
                            {client?.label} &#x2715;
                          </span>
                        ))}
                      </div>
                    )}
                  </PanelBarItem>
                </PanelBar>
              </FilterSection>
              <div className="tw-space-y-5 tw-min-h-[40dvh]">
                {clients?.map((client) => (
                  <CheckboxInput
                    disabled={selectedClientType != "B2B"}
                    key={client?.value}
                    id={client?.label}
                    name="client"
                    value={client?.value}
                    checked={selectedClients?.some(
                      (selected) => selected?.value === client?.value
                    )}
                    onChange={() => handleClientSelection(client?.value)}
                    label={client?.label}
                  />
                ))}
              </div>
            </>
          )}
          <div className="tw-flex tw-gap-5 tw-sticky tw-w-full tw-left-0 tw-p-5 tw-bottom-0 bg-white">
            <Button
              type="button"
              className="tw-w-full"
              onClick={handleReset}
              themeColor={null}
            >
              Reset
            </Button>
            <Button
              type="button"
              className="tw-w-full"
              onClick={handleSubmit}
              themeColor={"primary"}
            >
              Apply
            </Button>
          </div>
        </form>
        {loading && <LoadingPanel />}
      </Sidesheet>
    </div>
  );
}

const FilterSection = ({ title, children }) => (
  <div className="tw-mb-8">
    <label className="tw-font-semibold tw-text-base tw-block tw-mb-2">
      {title}
    </label>
    {children}
  </div>
);

const RadioInput = ({ id, name, value, checked, onChange, label }) => (
  <div className="tw-space-x-2">
    <input
      type="radio"
      id={id}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      className="tw-cursor-pointer"
    />
    <label htmlFor={id} className="tw-cursor-pointer">
      {label.charAt(0).toUpperCase() + label.slice(1)}
    </label>
  </div>
);

const CheckboxInput = ({
  disabled,
  id,
  name,
  value,
  checked,
  onChange,
  label,
}) => (
  <div className="tw-space-x-2 tw-whitespace-nowrap">
    <input
      disabled={disabled}
      type="checkbox"
      id={id}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      className="tw-cursor-pointer"
    />
    <label htmlFor={id} className="tw-cursor-pointer tw-whitespace-nowrap">
      {label.charAt(0).toUpperCase() + label.slice(1)}
    </label>
  </div>
);

const HandleDateComponent = ({
  calendarToggle,
  calendarDate,
  handleDateChange,
}) => (
  <div className="tw-relative tw-flex">
    <label
      className={`custom-border-primary tw-block tw-rounded-md tw-text-sm tw-px-3 tw-py-1.5 ${
        !calendarToggle
          ? "tw-opacity-40 tw-cursor-not-allowed"
          : "tw-cursor-pointer"
      }`}
      htmlFor="calendar"
      onClick={() => document.getElementById("calendar").showPicker()}
    >
      {formatDate(calendarDate)}
    </label>
    <input
      type="date"
      id="calendar"
      value={calendarDate}
      disabled={!calendarToggle}
      onChange={handleDateChange}
      className="tw-opacity-0 tw-absolute tw-bottom-0 tw-w-0 tw-pointer-events-none customDatePicker"
    />
  </div>
);

import React, { useCallback, useEffect, useState } from "react";
import ModalLayout from "../common/ModalLayout";
import { APIConfig } from "../../utils/constants/api.constants";
import axiosService from "../../init/axios";
import { convertClientsArray, getFormattedDate, showErrorMessage } from "../../utils/helpers";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import {
  numberRegex,
  phoneValidator,
  urlRegex,
} from "../../utils/forms/Validators";
import { useSelector } from "react-redux";
import { TIME_SLOTS } from "./shared/trip-enums";
import { CarDetailsForm } from "./shared/CarDetailsForm";
import { ClientSelection } from "./shared/ClientSection";
import { PickDropForm } from "./shared/PickDropForm";

const BUFFER_TIME = 2;

const AddTaskModal = ({ handleModalClose }) => {
  const clientDetails = useSelector((state) => state.auth?.clientDetails);
  const [currentStep, setCurrentStep] = useState(
    clientDetails?.clientId ? 2 : 1
  );
  const [selectedClient, setSelectedClient] = useState("");
  const navigate = useNavigate();
  const [address, setAddress] = useState({
    pickup: "",
    drop: "",
  });
  const [clients, setClients] = useState([]);
  const setAddressCallback = useCallback((newAddress) => {
    setAddress(newAddress);
  }, []);
  const [savedAddresses, setSavedAddresses] = useState([]);
  const [checkListData, setCheckListData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [loading, setLoading] = useState({
    locationInput: false,
  });
  const commonDetails = {
    customerName: "",
    customerPhoneNumber: "",
    customerSecondaryPhoneNumber: "",
    clientName: "",
    clientNumber: "",
    checklistId: "",
    date: getFormattedDate(),
    timeSlot: "",
    txnTimeMinutes: "",
    qcUrl: "",
    saveAddress: false,
  };
  const [formDetails, setFormDetails] = useState({
    pickupDetails: { ...commonDetails },
    dropDetails: { ...commonDetails },
    otherDetails: {
      expectedPrice: "",
      carType: "", //
      transmissionType: "", //
      registrationNumber: "", //
      fuelType: "", //
    },
  });

  const getClients = async () => {
    let baseUrl =
      process.env.REACT_APP_USER_BASE_URL + APIConfig.clients.getClients;

    await axiosService
      .get(baseUrl)
      .then((data) => {
        let newArr = convertClientsArray(data.data.clients);
        setClients(newArr);
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  const fetchChecklistData = async () => {
    axiosService
      .get(
        process.env.REACT_APP_USER_BASE_URL +
          `/admin/checklist/client/${
            selectedClient?.clientId || clientDetails?.clientId
          }`
      )
      .then((response) => {
        const data = response?.data;
        setCheckListData(data);
      })
      .catch((err) => {
        showErrorMessage(err);
      });
  };

  const fetchSavedAddresses = async () => {
    setLoading((prev) => ({ ...prev, locationInput: true }));
    axiosService
      .get(
        process.env.REACT_APP_USER_BASE_URL +
          APIConfig.clients.getClientsAddresses(
            selectedClient?.clientId || clientDetails?.clientId
          )
      )
      .then((response) => {
        const data = response.data;
        const addresses = data?.addresses;
        setSavedAddresses(addresses);
        setLoading((prev) => ({ ...prev, locationInput: false }));
      })
      .catch((err) => {
        showErrorMessage(err);
        setLoading((prev) => ({ ...prev, locationInput: false }));
      });
  };

  const showError = (message) => {
    toast.error(message);
    return false;
  };

  const handleFinalProceed = () => {
    const { pickupDetails, dropDetails } = formDetails;

    if (!address.pickup) {
      return showError("Please enter a pickup address.");
    }

    if (!address.drop) {
      return showError("Please enter a drop address.");
    }

    if (address?.pickup?.description == address?.drop?.description) {
      return showError("Pickup and Drop address can't be the same.");
    }

    const validateDetails = (details, type, additionalFields = []) => {
      const requiredFields = [...commonFields, ...additionalFields];

      for (const field of requiredFields) {
        const value = String(details[field] || "").trim();

        if (!value) {
          return showError(
            `Please enter ${field
              ?.replace(/([A-Z])/g, " $1")
              ?.toLowerCase()} for ${type}.`
          );
        }
      }

      return true;
    };
    const commonFields = [
      "customerName",
      "customerPhoneNumber",
      "clientName",
      "clientNumber",
    ];

    const pickupFields = ["timeSlot", "checklistId"];
    const isPickupValid = validateDetails(
      pickupDetails,
      "pickup",
      pickupFields
    );
    const isDropValid = validateDetails(dropDetails, "drop");

    if (!isPickupValid || !isDropValid) return;

    if (
      !formDetails.pickupDetails.txnTimeMinutes ||
      !numberRegex.test(formDetails.pickupDetails.txnTimeMinutes)
    ) {
      return showError("Please add pickup transaction time in minutes");
    }
    if (
      !formDetails.dropDetails.txnTimeMinutes ||
      !numberRegex.test(formDetails.dropDetails.txnTimeMinutes)
    ) {
      return showError("Please add drop transaction time in minutes");
    }

    if (formDetails?.pickupDetails?.qcUrl) {
      if (!urlRegex.test(formDetails.pickupDetails.qcUrl)) {
        return showError("Please provide a valid URL for the pickup QC.");
      }
    }
    if (formDetails?.dropDetails?.qcUrl) {
      if (!urlRegex.test(formDetails.dropDetails.qcUrl)) {
        return showError("Please provide a valid URL for the drop QC.");
      }
    }

    //
    const getCurrentTimePlusTwoHours = () => {
      const now = new Date();
      now.setHours(now.getHours() + BUFFER_TIME);
      return now;
    };

    // Get the current time plus two hours
    const currentTimePlusTwoHours = getCurrentTimePlusTwoHours();
    const selectedTimeSlot = pickupDetails?.timeSlot;

    if (selectedTimeSlot) {
      const selectedTimeSlotValue = TIME_SLOTS.find(
        (slot) => slot.value === selectedTimeSlot
      )?.value;

      if (selectedTimeSlotValue) {
        const timeslotDate = new Date(pickupDetails?.date || "");
        const [slotHours, slotMinutes] = selectedTimeSlotValue.split(":");
        timeslotDate.setHours(parseInt(slotHours), parseInt(slotMinutes), 0, 0);

        if (timeslotDate < currentTimePlusTwoHours) {
          return showError(
            "Selected time slot must be at least 2 hours in the future."
          );
        }
      }
    }

    // Validate phone numbers
    const pickupPhoneError = phoneValidator(pickupDetails?.customerPhoneNumber);
    const dropPhoneError = phoneValidator(dropDetails?.customerPhoneNumber);

    if (pickupPhoneError || dropPhoneError) {
      return showError(
        pickupPhoneError ||
          dropPhoneError ||
          "Please enter a valid customer phone number."
      );
    }

    // Proceed to the next step
    setCurrentStep(3);
  };

  const handleAddTask = async () => {
    if (!formDetails.otherDetails.carType) {
      return showError("Please select car type");
    }

    if (!formDetails.otherDetails.transmissionType) {
      return showError("Please select transmission type");
    }

    if (!formDetails.otherDetails.fuelType) {
      return showError("Please select fuel type");
    }

    const createLocationDetails = (location) => {
      if (!location) return;

      const { main_text, secondary_text } = location.structured_formatting;
      const [heading, ...subHeading] = location.description.split(",");
      return {
        placeId: location.place_id || "",
        locality: location.locality || "",
        heading: main_text || heading?.trim() || "",
        subHeading: secondary_text || subHeading?.join(",")?.trim() || "",
        formattedAddress: location.description || "",
        pincode: location.pincode || "",
        city: location.city || "",
        state: location.state || "",
        lat: location.coordinates.lat || "",
        lng: location.coordinates.lng || "",
        label: "",
        regionId: selectedClient.clientId,
      };
    };

    const payload = {
      action: "CREATE_TRIP",
      actionDetails: {
        actionBy: "",
        uuid: uuidv4(),
        clientId: selectedClient?.clientId || clientDetails?.clientId,
        clientName: selectedClient?.clientName || clientDetails?.clientName,
        journeyMode: "ONE_WAY",
        tripBookingMode: "SCHEDULED",
        pickupDetails: {
          locationDetails: {
            type: "CUSTOM",
            addToSavedAddresses: formDetails.pickupDetails.saveAddress,
            details: createLocationDetails(address.pickup),
          },
          customerDetails: {
            name: formDetails.pickupDetails.customerName,
            phoneNumber: formDetails.pickupDetails.customerPhoneNumber,
          },
          pocDetails: {
            name: formDetails.pickupDetails.clientName,
            phoneNumber: formDetails.pickupDetails.clientNumber,
          },
          checklistId: formDetails.pickupDetails.checklistId.checklistId,
          slotStartTime: `${formDetails.pickupDetails.date} ${formDetails.pickupDetails.timeSlot}`,
          txnTimeMinutes: formDetails.pickupDetails.txnTimeMinutes,
          checklistParams: {
            qcUrl: formDetails?.pickupDetails?.qcUrl,
          },
        },
        dropDetails: {
          locationDetails: {
            type: "CUSTOM",
            addToSavedAddresses: formDetails.dropDetails.saveAddress,
            details: createLocationDetails(address.drop),
          },
          customerDetails: {
            name: formDetails.dropDetails.customerName,
            phoneNumber: formDetails.dropDetails.customerPhoneNumber,
          },
          pocDetails: {
            name: formDetails.dropDetails.clientName,
            phoneNumber: formDetails.dropDetails.clientNumber,
          },
          checklistId: formDetails.dropDetails.checklistId.checklistId,
          txnTimeMinutes: formDetails.dropDetails.txnTimeMinutes,
          checklistParams: {
            qcUrl: formDetails?.dropDetails?.qcUrl,
          },
          // slotStartTime: `${formDetails.dropDetails.date} ${formDetails.dropDetails.timeSlot}`,
        },
        metadata: {},
        assetDetails: {
          type: "CAR",
          details: {
            make: "",
            model: "",
            carType: formDetails.otherDetails.carType.toUpperCase(),
            regoNo: formDetails.otherDetails.registrationNumber,
            transmissionType:
              formDetails.otherDetails.transmissionType.toUpperCase(),
            fuelType: formDetails.otherDetails.fuelType.toUpperCase(),
            year: "",
          },
        },
      },
    };

    setIsSubmitting(true);

    axiosService
      .post(process.env.REACT_APP_TRIP_BASE_URL + `/admin/trip`, payload)
      .then((res) => {
        toast.success("New task created successfully");
        setIsSubmitting(false);
        navigate(`/home/trip/${res.data.tripId}/details`);
      })
      .catch((err) => {
        setIsSubmitting(false);
        toast.error("Error Creating Trip");
        handleModalClose();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (!clientDetails?.clientId) getClients();
  }, []);

  useEffect(() => {
    if (selectedClient?.clientId || clientDetails?.clientId) {
      fetchChecklistData();
      fetchSavedAddresses();
    }
  }, [selectedClient?.clientId, clientDetails?.clientId]);

  const formattedSavedAddresses = savedAddresses?.map(
    (item) => item?.formattedAddress
  );

  const checkListIds = checkListData && checkListData.map((item) => item);

  return (
    <ModalLayout title={"Add Task"} onClose={handleModalClose}>
      <div className="tw-min-w-96">
        {currentStep === 1 && (
          <ClientSelection
            clients={clients}
            selectedClient={selectedClient}
            handleClientChange={(e) => setSelectedClient(e)}
            onProceed={() => setCurrentStep(2)}
          />
        )}
        {currentStep === 2 && (
          <>
            <PickDropForm
              loading={loading}
              savedAddresses={savedAddresses}
              addressModal={addressModal}
              setAddressModal={setAddressModal}
              address={address}
              setAddressCallback={setAddressCallback}
              formattedSavedAddresses={formattedSavedAddresses}
              formDetails={formDetails}
              checkListIds={checkListIds}
              // handleGoBack={handleGoBack}
              handleFinalProceed={handleFinalProceed}
              setFormDetails={setFormDetails}
            />
          </>
        )}
        {currentStep === 3 && (
          <CarDetailsForm
            handleAddTask={handleAddTask}
            formDetails={formDetails}
            // handleGoBack={handleGoBack}
            isSubmitting={isSubmitting}
            setFormDetails={setFormDetails}
          />
        )}
      </div>
    </ModalLayout>
  );
};

export default AddTaskModal;





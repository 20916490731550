import React from "react";
import { Input } from "@progress/kendo-react-inputs";

const OtpInput = ({ length = 4, value = [], onOtpChange }) => {
  // Ensure the value array matches the required length
  const otpArray = [...value, ...Array(length - value.length).fill("")];

  // Handle OTP digit changes
  const handleInputChange = (index, digit) => {
    if (/^\d?$/.test(digit)) {
      // Ensure only digits
      const newOtp = [...otpArray];
      newOtp[index] = digit;
      onOtpChange(newOtp);

      // Automatically focus next input if digit is entered
      if (digit !== "" && index < length - 1) {
        document.getElementById(`otp-${index + 1}`).focus();
      }

      // Automatically move to the previous input if empty and user presses backspace
      if (digit === "" && index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }
  };

  // Handle key navigation
  const handleKeyDown = (index, e) => {
    const inputElement = e.target;

    if (e.key === "ArrowRight") {
      // Check if the cursor is at the end of the input value
      if (
        inputElement.selectionStart === inputElement.value.length && // Cursor at the end
        index < length - 1
      ) {
        document.getElementById(`otp-${index + 1}`).focus(); // Move to the next input
      }
    } else if (e.key === "ArrowLeft") {
      // Check if the cursor is at the start of the input value
      if (
        inputElement.selectionStart === 0 && // Cursor at the beginning
        index > 0
      ) {
        document.getElementById(`otp-${index - 1}`).focus(); // Move to the previous input
      }
    }
  };

  return (
    <div className="tw-flex tw-gap-2">
      {otpArray.map((digit, index) => (
        <Input
          key={index}
          id={`otp-${index}`}
          value={digit}
          onChange={(e) => handleInputChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          maxLength={1}
          autoComplete="off"
          className="tw-w-10 tw-text-center tw-text-md tw-border tw-border-gray-300 tw-rounded p-2"
        />
      ))}
    </div>
  );
};

export default OtpInput;

import { updateSidebarToggle } from "../slices/componentStatesSlice";

export default function SidebarHamburger({ dispatch, isOpen }) {
  const handleCollapsableSidebar = () => dispatch(updateSidebarToggle(!isOpen));
  return (
    <>
      <span
        className="tw-bg-primary tw-h-8 tw-w-8 tw-rounded-full tw-grid tw-place-content-center tw-cursor-pointer"
        onClick={handleCollapsableSidebar}
      >
        <svg
          className={`tw-w-8 tw-duration-300 tw-h-8 ${
            isOpen ? "tw-rotate-180" : "tw-rotate-0"
          }`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          role="presentation"
        >
          <path
            fill="white"
            fill-rule="evenodd"
            d="M10.294 9.698a.99.99 0 0 1 0-1.407 1.01 1.01 0 0 1 1.419 0l2.965 2.94a1.09 1.09 0 0 1 0 1.548l-2.955 2.93a1.01 1.01 0 0 1-1.42 0 .99.99 0 0 1 0-1.407l2.318-2.297z"
          ></path>
        </svg>
      </span>
    </>
  );
}

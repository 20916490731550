import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TripConfig as config } from "../../config/tripConfig";

export default function PricingFilters({
  tripConfigState,
  handleConfigChange,
}) {
  return (
    <div className="tw-flex tw-items-center tw-gap-5">
      <div className="tw-w-full tw-max-w-60">
        <label className="tw-text-sm tw-whitespace-nowrap tw-block">
          Trip type
        </label>
        <DropDownList
          data={config.derivedTripType}
          textField="label"
          dataItemKey="value"
          value={tripConfigState.tripType}
          onChange={(e) => handleConfigChange("tripType", e)}
        />
      </div>
      <div className="tw-w-full tw-max-w-60">
        <label className="tw-text-sm tw-whitespace-nowrap tw-block">
          Trip sub type
        </label>
        <DropDownList
          data={config.journeyMode}
          textField="label"
          dataItemKey="value"
          value={tripConfigState.journeyMode}
          onChange={(e) => handleConfigChange("journeyMode", e)}
        />
      </div>
      <div className="tw-w-full tw-max-w-60">
        <label className="tw-text-sm tw-whitespace-nowrap tw-block">
          Trip duration
        </label>
        <DropDownList
          data={config.durationType}
          textField="label"
          dataItemKey="value"
          value={tripConfigState.durationType}
          onChange={(e) => handleConfigChange("durationType", e)}
        />
      </div>
    </div>
  );
}

import { useNavigate } from "react-router-dom";
export default function Unauthorized() {
  const navigate = useNavigate();
  return (
    <div className="tw-grid tw-place-content-center tw-w-full tw-h-screen">
      <div className="tw-text-center">
        <h1 className="tw-text-5xl tw-font-bold">403</h1>
        <h2 className="tw-text-2xl tw-font-semibold tw-mt-2">
          Unauthorized Access
        </h2>
        <p className="tw-text-base tw-mt-1 tw-text-gray-400">
          Sorry, you don't have permission to view this page.
        </p>
        <div className="tw-mt-6">
          <button
            onClick={() => navigate("/home/trip")}
            className="tw-text-primary hover:tw-underline tw-bg-transparent tw-border-none"
          >
            Go Back to Home
          </button>
        </div>
      </div>
    </div>
  );
}

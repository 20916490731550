import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import ModalLayout from "../../../../common/ModalLayout";
import OtpInput from "../../../../common/OTPInput";
import {
  convertEnumToLabel,
  convertLabelToEnum,
  showErrorMessage,
  showToastMessage,
} from "../../../../../utils/helpers";
import StatusToCancellation from "./StatusToCancellation";
import { isValidRegistrationNo } from "../../../../../config/statusModificationConfig";
import FinalPrice from "./FinalPrice";
import BookingModificationMap from "./BookingModificationMap";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import axiosService from "../../../../../init/axios";
import LoadingSpinner from "../../../../common/LoadingSpinner";
import { add } from "lodash";

function BookingModificationModal({
  closeModal,
  tripDetails,
  tripId,
  refreshTripDetails,
  setRefreshTripDetails,
  refreshTripCalled,
  setRefreshTripCalled,
}) {
  const [statusRelatedData, setStatusRelatedData] = useState(null);
  const [changedStatus, setChangedStatus] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmitDisabled, setSubmitDisabled] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cancellationChargesAvailble, setCancellationChargesAvailable] =
    useState(false);

  const [formData, setFormData] = useState({
    otp: [],
    carNumber: "",
    selectedStatus: null,
    selectedReason: null,
    selectedSubReason: null,
  });

  // Using a constant for replacing spaces with underscores
  const key = `${tripDetails?.stage?.replaceAll(" ", "_")}_to_${changedStatus}`;

  const currentTransition = useMemo(
    () => statusRelatedData?.transitionMetadata?.[key] || {},
    [statusRelatedData, key]
  );
  const elements = useMemo(
    () => currentTransition?.elements || [],
    [currentTransition]
  );

  const note = currentTransition?.note;

  // Validation for Forms
  const validateForm = useCallback(() => {
    const fieldErrors = {};
    elements.forEach((element) => {
      switch (element.type) {
        case "REASON__SUB_REASON":
          const reasonDetails = element.metadata?.reasonSubReasonDetails || {};
          if (!formData.selectedReason) {
            fieldErrors.selectedReason =
              reasonDetails.reasonLabel || "Reason is required.";
          }
          if (!formData.selectedSubReason) {
            fieldErrors.selectedSubReason =
              reasonDetails.subReasonLabel || "Sub-reason is required.";
          }
          break;

        case "CANCELLATION_TYPE":
          if (!formData.cancellationType) {
            fieldErrors.cancellationType = "Cancellation type is required.";
          }
          break;

        case "TRIP_START_OTP":
          if (formData.otp.join("").length !== 4) {
            fieldErrors.otp = "Please enter a 4-digit OTP.";
          }
          break;

        case "VEHICLE_REGISTRATION_NUMBER":
          if (!formData.carNumber) {
            fieldErrors.carNumber = "Car number is required.";
          } else if (!isValidRegistrationNo(formData.carNumber)) {
            fieldErrors.carNumber = "Invalid vehicle registration number.";
          }
          break;

        default:
          break;
      }
    });

    setErrors(fieldErrors);
    setSubmitDisabled(Object.keys(fieldErrors).length > 0);
  }, [elements, formData]);

  const resetFormOnStatusChange = useCallback(() => {
    setFormData({
      otp: [],
      carNumber: "",
      selectedStatus: changedStatus,
      selectedReason: null,
      selectedSubReason: null,
      cancellationType: null,
      cancellationReason: null,
      cancellationSubReason: null,
    });
    setErrors({});
  }, [changedStatus]);

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // fetching data for status modification
  const handleGetStatusModificationInfo = useCallback(() => {
    setLoading(true);
    const url =
      process.env.REACT_APP_TRIP_BASE_URL_NEW +
      APIConfig.tripManagement.getStatusDetails(tripId);
    axiosService
      .get(url)
      .then((e) => {
        setStatusRelatedData(e.data);
      })
      .catch((err) => showErrorMessage(err))
      .finally(() => {
        setLoading(false);
      });
  }, [tripId]);

  const handleUpdateStatus = (data) => {
    setLoading(true);
    const url =
      process.env.REACT_APP_TRIP_BASE_URL_NEW +
      APIConfig.tripManagement.updateTripStatus(tripId);
    axiosService
      .put(url, data)
      .then((e) => {
        if (e.data?.msg) {
          showToastMessage(e.data?.msg);
        } else {
          showToastMessage("Stage updated successfully");
        }
        closeModal();
        if (!refreshTripCalled) {
          setRefreshTripCalled(true);
        }
        setRefreshTripDetails(!refreshTripDetails);
      })
      .catch((err) => showErrorMessage(err))
      .finally(() => setLoading(false));
  };

  const handleSubmit = () => {
    if (!statusRelatedData) return;
  
    const otpValue = formData?.otp?.join("");
  
    // Helper function to initialize or access `additionalDetails`
    const getAdditionalDetails = (detail) => {
      detail.additionalDetails = detail.additionalDetails || {};
      return detail.additionalDetails;
    };
  
    const stageChangeDetail = elements?.reduce((detail, element) => {
      let additionalDetails;
      
      if(element?.type === "VEHICLE_REGISTRATION_NUMBER" || element?.type === "CANCELLATION_TYPE"){
        additionalDetails = getAdditionalDetails(detail);
      }
      switch (element?.type) {
        case "TRIP_START_OTP":
          detail.otp = otpValue;
          break;
        case "VEHICLE_REGISTRATION_NUMBER":
          additionalDetails.carNumber = formData?.carNumber;
          break;
        case "REASON__SUB_REASON":
          detail.reason = formData?.selectedReason;
          detail.subReason = formData?.selectedSubReason;
          break;
        case "CANCELLATION_TYPE":
          if (cancellationChargesAvailble) {
            detail.waiveOff = {
              reason: formData?.cancellationReason,
              subReason: formData?.cancellationSubReason,
            };
          }
          if(formData?.cancellationType){
            additionalDetails.cancelledBy = formData?.cancellationType
          }
          break;
        default:
          break;
      }
      return detail;
    }, {});

    const data = {
      currentStage: tripDetails?.stage,
      finalStage: changedStatus,
      stageChangeDetail,
    };
  
    // Uncomment the following line to handle the update
    handleUpdateStatus(data);
  };

  
  useEffect(() => {
    handleGetStatusModificationInfo();
  }, [handleGetStatusModificationInfo]);

  useEffect(() => {
    resetFormOnStatusChange();
  }, [changedStatus, resetFormOnStatusChange]);

  useEffect(() => {
    validateForm();
  }, [formData, elements, validateForm]);

  const renderDynamicElement = (element, index) => {
    const { type, metadata } = element;

    switch (type) {
      case "CANCELLATION_TYPE":
        return (
          <StatusToCancellation
            key={index}
            formData={formData}
            handleChange={handleChange}
            statusRelatedData={statusRelatedData}
            metadata={metadata}
            tripDetails={tripDetails}
            changedStatus={changedStatus}
            cancellationChargesAvailble={cancellationChargesAvailble}
            setCancellationChargesAvailable={setCancellationChargesAvailable}
          />
        );

      case "REASON__SUB_REASON":
        const reasonDetails = metadata?.reasonSubReasonDetails || {};
        return (
          <div key={index} className="tw-flex tw-gap-4">
            <div className="tw-mt-4 tw-w-1/2">
              <label className="tw-font-bold tw-text-sm">
                {reasonDetails.reasonLabel}
              </label>
              <DropDownList
                data={reasonDetails?.data?.map((item) => item.reason) || []}
                value={formData.selectedReason}
                onChange={(e) => {
                  handleChange("selectedReason", e.target.value);
                  handleChange("selectedSubReason", null);
                }}
              />
            </div>
            <div className="tw-mt-4 tw-w-1/2 ">
              <label className="tw-font-bold tw-text-sm">
                {reasonDetails.subReasonLabel}
              </label>
              <DropDownList
                data={
                  reasonDetails?.data?.find(
                    (item) => item?.reason === formData?.selectedReason
                  )?.subReasons || []
                }
                value={formData.selectedSubReason}
                onChange={(e) =>
                  handleChange("selectedSubReason", e.target.value)
                }
              />
            </div>
          </div>
        );

      case "TRIP_FARE_BREAKUP":
        return <FinalPrice key={index} metadata={metadata} />;

      case "TRIP_START_OTP":
        return (
          <div key={index} className="tw-mt-4 tw-w-1/2">
            <label className="tw-font-bold tw-text-sm">Customer OTP</label>
            <OtpInput
              length={4}
              value={formData.otp}
              onOtpChange={(newOtp) => handleChange("otp", newOtp)}
            />
          </div>
        );

      case "VEHICLE_REGISTRATION_NUMBER":
        return (
          <div key={index} className="tw-mt-4 tw-w-1/2">
            <label className="tw-font-bold tw-text-sm">Car Number Plate</label>
            <Input
              value={formData.carNumber}
              onChange={(e) =>
                handleChange("carNumber", e.target.value.toUpperCase())
              }
              placeholder="HR34DF3453"
            />
            {errors.carNumber && (
              <p className="tw-text-red-500 tw-text-xs mt-1">
                {errors.carNumber}
              </p>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <ModalLayout onClose={closeModal} title="B2C Driver Details">
      <div className="tw-w-[60vw] tw-max-h-[60dvh] tw-overflow-auto">
        {loading ? (
          <div className="tw-flex tw-justify-center tw-items-center tw-w-full">
            <LoadingSpinner height={40} width={40} color={"lightgray"} />
          </div>
        ) : (
          <div className="tw-flex tw-gap-4">
            {/* Left-hand side: Dynamic elements */}
            <div
              className={`${
                elements?.some(
                  (element) =>
                    element?.type === "TRIP_DRIVER_LOCATION_MAP" ||
                    element?.type === "DRIVER_LOCATION_MAP"
                )
                  ? "tw-w-2/3"
                  : "tw-w-full"
              }`}
            >
              {/* Status */}
              <div className="tw-w-full">
                <label className="tw-font-bold tw-text-sm">Status</label>
                <DropDownList
                  data={statusRelatedData?.stateTransitionDetails?.finalStates?.map(
                    (item) => item && convertEnumToLabel(item)
                  )}
                  value={
                    formData?.selectedStatus &&
                    convertEnumToLabel(formData?.selectedStatus)
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    setChangedStatus(value && convertLabelToEnum(value));
                    handleChange(
                      "selectedStatus",
                      value && convertLabelToEnum(value)
                    );
                  }}
                  className="tw-w-full"
                />
              </div>

              {/* Render dynamic elements */}
              {elements?.map(renderDynamicElement)}

              {/* Note */}
              {note && (
                <div className="tw-mt-4">
                  <p className="tw-text-xs">
                    <span className="tw-font-bold">Note:&nbsp;</span>
                    {note}
                  </p>
                </div>
              )}

              {/* Submit Button */}

              <div className="tw-mt-8">
                <Button
                  themeColor="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitDisabled || !statusRelatedData}
                  className="tw-px-8 tw-py-2 tw-mt-4"
                >
                  Submit
                </Button>
              </div>
            </div>

            {/* Map Section */}
            {elements?.some(
              (element) =>
                element?.type === "TRIP_DRIVER_LOCATION_MAP" ||
                element?.type === "DRIVER_LOCATION_MAP"
            ) && (
              <div className="tw-w-1/3">
                {elements?.map((element, index) => {
                  if (
                    element?.type === "TRIP_DRIVER_LOCATION_MAP" ||
                    element?.type === "DRIVER_LOCATION_MAP"
                  ) {
                    return (
                      <BookingModificationMap
                        key={index}
                        tripDetails={tripDetails}
                        metadata={element?.metadata} // Pass the metadata for the map
                      />
                    );
                  }
                  return null;
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </ModalLayout>
  );
}

export default BookingModificationModal;

export const TripConfig = {
  tripType: [
    {
      label: "In City",
      value: "IN_CITY",
    },
    {
      label: "Outstation",
      value: "OUTSTATION",
    },
  ],

  derivedTripType: [
    {
      label: "In City",
      value: "IN_CITY",
    },
    {
      label: "Outstation Near",
      value: "OUTSTATION_NEAR",
    },
    {
      label: "Outstation Far",
      value: "OUTSTATION_FAR",
    },
  ],

  journeyMode: [
    {
      label: "Round Trip",
      value: "ROUND_TRIP",
    },
    {
      label: "One Way",
      value: "ONE_WAY",
    },
  ],

  scheduled: [
    {
      label: "Go Now",
      value: "GO_NOW",
    },
    {
      label: "Scheduled",
      value: "SCHEDULED",
    },
  ],

  stage: [
    {
      label: "Trip initiated",
      value: "TRIP_INITIATED",
    },
    {
      label: "Searching for driver",
      value: "SEARCHING_FOR_DRIVER",
    },
    {
      label: "Driver not found",
      value: "DRIVER_NOT_FOUND",
    },
    {
      label: "Driver Allocated",
      value: "DRIVER_ALLOCATED",
    },
    {
      label: "Driver on the way",
      value: "DRIVER_ON_THE_WAY",
    },
    {
      label: "Driver arrived",
      value: "DRIVER_ARRIVED",
    },
    {
      label: "Trip started",
      value: "TRIP_STARTED",
    },
    {
      label: "Trip end",
      value: "TRIP_END",
    },
    {
      label: "Trip completed",
      value: "TRIP_COMPLETED",
    },
    {
      label: "Trip canceled",
      value: "TRIP_CANCELED",
    },
    {
      label: "Trip disputed",
      value: "TRIP_DISPUTED",
    },
    {
      label: "Driver not found acknowledged",
      value: "DRIVER_NOT_FOUND_ACKNOWLEDGED",
    },

    {
      label: "Trip disputed",
      value: "TRIP_DISPUTED",
    },
  ],

  tripBookingMode: [
    {
      label: "Scheduled",
      value: "SCHEDULED",
    },
    {
      label: "On Demand",
      value: "ON_DEMAND",
    },
  ],

  tag: [
    {
      label: "Critical Zone",
      value: "CRITICAL_ZONE",
    },
  ],

  waiverStages: [
    "SEARCHING_FOR_DRIVER",
    "DRIVER_ALLOCATED",
    "DRIVER_ON_THE_WAY",
    "DRIVER_ARRIVED",
    "TRIP_STARTED",
    "TRIP_END",
  ],

  waiverReason: [
    {
      label: "Dispute",
      value: "DISPUTE",
    },
    {
      label: "Driver Issue",
      value: "DRIVER_ISSUE",
    },
    {
      label: "Pricing Issue",
      value: "PRICING_ISSUE",
    },
    {
      label: "Trip Issue",
      value: "TRIP_ISSUE",
    },
  ],

  waiverSubReason: [
    {
      label: "Cancellation Charge",
      value: "CANCELLATION_CHARGE",
      tag: "DRIVER_INFRACTION",
      reason: "DRIVER_ISSUE",
    },
    {
      label: "Reschedule Charge",
      value: "RESCHEDULE_CHARGE",
      tag: "DRIVER_INFRACTION",
      reason: "DRIVER_ISSUE",
    },
    {
      label: "Driver Drunk",
      value: "DRUNK",
      tag: "DRIVER_INFRACTION",
      reason: "DRIVER_ISSUE",
    },
    {
      label: "Driver Unhygenic",
      value: "UNHYGENIC",
      tag: "DRIVER_INFRACTION",
      reason: "DRIVER_ISSUE",
    },
    {
      label: "Driver Abandoned",
      value: "ABANDONED",
      tag: "DRIVER_INFRACTION",
      reason: "DRIVER_ISSUE",
    },
    {
      label: "Rude Driver",
      value: "RUDE",
      tag: "DRIVER_INFRACTION",
      reason: "DRIVER_ISSUE",
    },
    {
      label: "Charged more than promised",
      value: "CHARGED_MORE",
      tag: "AUTOPILOT_INFRACTION",
      reason: "PRICING_ISSUE",
    },
    {
      label: "Driver exceeded ETA",
      value: "DRIVER_ETA",
      tag: "AUTOPILOT_INFRACTION",
      reason: "TRIP_ISSUE",
    },
    {
      label: "Accident",
      value: "ACCIDENT",
      tag: "AUTOPILOT_INFRACTION",
      reason: "TRIP_ISSUE",
    },
    {
      label: "Challan Imposed",
      value: "CHALLAN",
      tag: "AUTOPILOT_INFRACTION",
      reason: "TRIP_ISSUE",
    },
  ],

  refundReason: [
    {
      label: "Driver Issue",
      value: "DRIVER_ISSUE",
    },
    {
      label: "Pricing Issue",
      value: "PRICING_ISSUE",
    },
    {
      label: "Trip Issue",
      value: "TRIP_ISSUE",
    },
  ],

  refundSubReason: [
    {
      label: "Driver Drunk",
      value: "DRUNK",
      tag: "DRIVER_INFRACTION",
      reason: "DRIVER_ISSUE",
    },
    {
      label: "Driver Unhygenic",
      value: "UNHYGENIC",
      tag: "DRIVER_INFRACTION",
      reason: "DRIVER_ISSUE",
    },
    {
      label: "Driver Abandoned",
      value: "ABANDONED",
      tag: "DRIVER_INFRACTION",
      reason: "DRIVER_ISSUE",
    },
    {
      label: "Rude Driver",
      value: "RUDE",
      tag: "DRIVER_INFRACTION",
      reason: "DRIVER_ISSUE",
    },
    {
      label: "Charged more than promised",
      value: "CHARGED_MORE",
      tag: "AUTOPILOT_INFRACTION",
      reason: "PRICING_ISSUE",
    },
    {
      label: "Driver exceeded ETA",
      value: "DRIVER_ETA",
      tag: "AUTOPILOT_INFRACTION",
      reason: "TRIP_ISSUE",
    },
    {
      label: "Accident",
      value: "ACCIDENT",
      tag: "AUTOPILOT_INFRACTION",
      reason: "TRIP_ISSUE",
    },
    {
      label: "Challan Imposed",
      value: "CHALLAN",
      tag: "AUTOPILOT_INFRACTION",
      reason: "TRIP_ISSUE",
    },
  ],

  refundStatusNonFinanceUser: [
    {
      label: "Pending",
      value: "PENDING",
    },
  ],

  refundStatusFinanceUser: [
    {
      label: "Pending",
      value: "PENDING",
    },
    {
      label: "Initiated",
      value: "INITIATED",
    },
    {
      label: "Completed",
      value: "COMPLETED",
    },
  ],

  coupons: ["WEARESORRY"],
  referralCoupons: ["ITSONUS", "JOURNEYONUS"],

  couponStatus: [
    {
      label: "Not Issued",
      value: "NOT_ISSUED",
    },
    {
      label: "Active",
      value: "ACTIVE",
    },
    {
      label: "Redeemed",
      value: "REDEEMED",
    },
  ],

  durationType: [
    {
      label: "Hours",
      value: "HOURS",
    },
    {
      label: "Days",
      value: "DAYS",
    },
  ],

  clientTypes: [
    {
      label: "B2B",
      value: "B2B",
    },
    // {
    //   label: "SUB",
    //   value: "B2C_SUBS",
    // },
    {
      label: "B2C",
      value: "B2C",
    },
    // {
    //   label: "C2B",
    //   value: "C2B",
    // },
  ],
};
export const statusModCTARoles = [
  "AP_UPDATE_TRIP_STAGE",
  "AP_GET_TRIP_TRANSITION",
];

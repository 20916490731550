import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { useSelector } from "react-redux";

export const pathsWithFiltered = [
  "/home/driver",
  "/home/driver/all",
  "/home/trip",
  "/home/pricing",
  "/home/subscriptions",
  "/home/region-management",
];

const CardLayout = ({ title, children, ComponentRender, ...props }) => {
  const { pathname } = useLocation();
  const showFilter = false || pathsWithFiltered.includes(pathname);
  const isOpen = useSelector((state) => state.component.sidebarToggle);

  return (
    <>
      <section className="tw-h-full tw-w-full tw-relative tw-overflow-y-auto">
        <nav
          className="tw-px-5 tw-bg-white tw-h-20 tw-z-10 tw-sticky tw-top-0 tw-flex tw-items-center tw-gap-5"
          style={{ borderBottom: "1px solid #E8E8E8" }}
        >
          {!isOpen && (
            <div
              className="tw-h-20 tw-mr-5 tw-flex tw-items-center"
              style={{ borderBottom: "1px solid #E8E8E8" }}
            >
              <Link className="tw-w-28" to={"/home/trip"}>
                <img src={logo} alt="Pathfinder" className="tw-w-full" />
              </Link>
            </div>
          )}
          <span className="tw-text-lg tw-font-medium tw-whitespace-nowrap">
            {title}
          </span>
          {ComponentRender && showFilter ? (
            <div className="tw-w-full">
              <ComponentRender {...props} />
            </div>
          ) : (
            <></>
          )}
        </nav>
        <div className="tw-p-5">{children}</div>
      </section>
    </>
  );
};

export default CardLayout;

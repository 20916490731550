import React, { useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { ChevronDownIcon } from "../../../../../assets/icons/ChevronDownIcon";
import { ChevronUpIcon } from "../../../../../assets/icons/ChevronUpIcon";

function FinalPrice({ metadata }) {
  
  const [showBreakup, setShowBreakup] = useState(false);

  // Extracting data from the new JSON structure
  const { customerPayableAmount, fareBreakup } = metadata || {};

  // Making changes as backend is not returning the contracted payload adding TOTAL_PRICE as custom.
  const pricingDetails =
  fareBreakup?.filter((item) => item.show) // Filter only items with item.show === true
    .map((item) => ({
      id: item.key,
      detail: item.label,
      amount: `₹${item.value}`,
      isTotal: item.key === "TOTAL_PRICE",
    })) || [];
  pricingDetails.push({
    id:'TOTAL_PRICE',
    detail:'Total Price',
    amount:`₹${customerPayableAmount}`,
    isTotal:true
  })

  const columns = [
    {
      field: "detail",
      title: "Pricing Details",
      width: "400",
      cell: (props) => (
        <td
          className={
            props.dataItem.isTotal
              ? "tw-font-bold tw-text-black"
              : "tw-font-normal tw-text-gray-700 tw-text-xs"
          }
        >
          {props.dataItem.detail}
        </td>
      ),
    },
    {
      field: "amount",
      title: "Amount",
      cell: (props) => (
        <td
          className={
            props.dataItem.isTotal
              ? "tw-font-bold tw-text-black "
              : "tw-font-normal tw-text-gray-700 tw-text-xs "
          }
        >
          {props.dataItem.amount}
        </td>
      ),
    },
  ];

  const toggleBreakup = () => {
    setShowBreakup(!showBreakup);
  };

  return (
    <div className="tw-mt-6 ">
      <p className="tw-m-0 tw-font-bold tw-text-sm">Final Price</p>
      <div className="tw-flex tw-justify-between tw-px-4 tw-py-2 tw-items-center tw-bg-gray-100 rounded border border-gray-200">
        <p className="tw-m-0 tw-text-xs">₹{customerPayableAmount || 0}</p>
        <Button
          fillMode={"link"}
          className="tw-text-primary hover:tw-text-primary hover:tw-no-underline tw-font-medium tw-text-xs"
          onClick={toggleBreakup}
        >
          Breakup info {showBreakup ? <ChevronUpIcon fill="#2756b3"/> : <ChevronDownIcon fill="#2756b3"/>}
        </Button>
      </div>

      {showBreakup && (
        <div className="tw-mt-4">
          <Grid data={pricingDetails}>
            {columns.map((col, index) => (
              <Column
                key={index}
                field={col.field}
                title={col.title}
                cell={col.cell}
                width={col.width}
              />
            ))}
          </Grid>
        </div>
      )}
    </div>
  );
}

export default FinalPrice;

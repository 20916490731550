import React, { useEffect, useState } from "react";
import CardLayout from "../components/common/CardLayout";
import { DropdownSearchSingleSelect } from "../utils/forms/NewFormComponents";
import { useDispatch, useSelector } from "react-redux";
import { updateRegionManagementFilter } from "../slices/regionSlice";
import ToggleSwitch from "../components/common/Components";
import axiosService from "../init/axios";
import { APIConfig } from "../utils/constants/api.constants";
import { checkMatchingRoles, showErrorMessage } from "../utils/helpers";
import LoadingPanel from "../components/common/LoadingPanel";
import { userPermissions } from "../config/userPermissionConfig";
import Unauthorized from "../components/common/Unauthorized";

export default function RegionManagement() {
  const [loading, setLoading] = useState(false);
  const regions = useSelector((state) => state.region?.mainRegions);
  const [toggleStatus, setToggleStatus] = useState([]);
  const userRoles = useSelector((state) => state.auth?.userRoles);

  const selectedRegionFilter = useSelector(
    (state) => state.region?.regionManagement
  );
  const dispatch = useDispatch();

  const onRegionFilterChange = (e) => {
    dispatch(updateRegionManagementFilter(e));
  };

  const handleRegionManagementChange = (key, value) => {
    setToggleStatus((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleB2CSubStatusChange = (e) => {
    handleRegionManagementChange("b2cEnabled", e.target.checked);
    const data = {
      regionId: selectedRegionFilter?.regionId,
      b2cEnabled: e.target.checked,
    };
    setRegionStatus(data, handleRegionManagementChange, setLoading);
  };

  const updatedRegions = regions.slice().map((item) => ({
    ...item,
    label: item.child
      ? `${item.regionName} (${item.parentRegionName})`
      : item.regionName,
  }));

  const TopFilter = () => (
    <div className="tw-w-full">
      <DropdownSearchSingleSelect
        options={updatedRegions}
        textField="label"
        onChange={onRegionFilterChange}
        value={selectedRegionFilter}
        backupMessage="No Options Available"
        parentClass="tw-relative tw-w-64"
      />
    </div>
  );

  useEffect(() => {
    if (
      checkMatchingRoles(userRoles, userPermissions.regionMangementModification)
    ) {
      setLoading(true);
      if (selectedRegionFilter) {
        const regionId = selectedRegionFilter?.regionId;
        getRegionStatus(regionId, handleRegionManagementChange, setLoading);
      }
    }
  }, [selectedRegionFilter]);
  if (
    !checkMatchingRoles(userRoles, userPermissions.regionMangementModification)
  )
    return <Unauthorized />;
  return (
    <CardLayout title={"Region Management"} ComponentRender={TopFilter}>
      <div className="tw-grid tw-grid-cols-5">
        <label>B2C Sub Status</label>
        <ToggleSwitch
          checked={toggleStatus.b2cEnabled}
          onChange={handleB2CSubStatusChange}
          disabled={
            !checkMatchingRoles(
              userRoles,
              userPermissions.regionMangementModification
            ) || loading
          }
        />
      </div>
      {loading && <LoadingPanel />}
    </CardLayout>
  );
}

const getRegionStatus = async (
  regionId,
  handleRegionManagementChange,
  setLoading
) => {
  await axiosService
    .get(
      process.env.REACT_APP_TRIP_BASE_URL +
        APIConfig.tripManagement.getRegionManagementStatus(regionId)
    )
    .then((response) => {
      handleRegionManagementChange("b2cEnabled", response?.data?.b2cEnabled);
      setLoading(false);
    })
    .catch((error) => {
      showErrorMessage(error);
      setLoading(false);
    });
};

const setRegionStatus = async (
  data,
  handleRegionManagementChange,
  setLoading
) => {
  setLoading(true);
  await axiosService
    .put(
      process.env.REACT_APP_TRIP_BASE_URL +
        APIConfig.tripManagement.setRegionManagementStatus,
      data
    )
    .then((response) => {
      handleRegionManagementChange("b2cEnabled", response?.data?.b2cEnabled);
      setLoading(false);
    })
    .catch((error) => {
      showErrorMessage(error);
      setLoading(false);
    });
};

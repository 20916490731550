import React from "react";
import { useSelector } from "react-redux";

export const NotFound = () => {
  const tripManagementQueryParams = useSelector(
    (state) => state.component.tripManagementQueryParams
  );
  return (
    <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
      <div className="tw-text-center">
        <h1 className="tw-text-5xl tw-font-bold">404</h1>
        <p className="tw-text-lg">
          Oops! The page you are looking for doesn't exist.
        </p>
        <a
          href={"/home/trip" + tripManagementQueryParams}
          className="tw-text-primary hover:tw-underline"
        >
          Go back to Home
        </a>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import PricingChangeLogsTable from "./pricing-change-logs/PricingChangeLogsTable";
import { useSelector } from "react-redux";
import { TripConfig } from "../../config/tripConfig";
import { Button } from "@progress/kendo-react-buttons";
import ButtonGroup from "../common/ButtonGroup";
import PricingFilters from "./PricingFilters";
import PricingConfigurationForm from "./PricingConfigurationForm";
import PricingOtherChanges from "./pricing-other-changes/PricingOtherChanges";
import ModalLayout from "../common/ModalLayout";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const ButtonGroupList = [
  {
    label: "Normal Pricing",
    value: "PRICING_AND_DILUTER_CONFIG_V2",
    diluterValue: "VAC_CONFIG",
  },
  {
    label: "Special Pricing",
    value: "NYE_PRICING_AND_DILUTER_CONFIG_V2",
    diluterValue: "NYE_VAC_CONFIG",
  },
];

const PricingConfigHome = () => {
  const [viewGraph, setViewGraph] = useState(false);
  const [gridData, setGridData] = useState([]);

  const selectedPricingRegionFilter = useSelector(
    (state) => state.region?.pricing
  );

  const [expanded, setExpanded] = useState([""]);
  const [tripConfigState, setTripConfigState] = useState({
    tripType: TripConfig.derivedTripType[0],
    journeyMode: TripConfig.journeyMode[0],
    durationType: TripConfig.durationType[0],
  });
  //
  const [activeValue, setActiveValue] = useState(ButtonGroupList[0]);

  const handleConfigChange = (key, e) => {
    setTripConfigState((prev) => ({
      ...prev,
      [key]: e.value,
    }));
  };

  useEffect(() => {
    setExpanded([""]);
  }, [selectedPricingRegionFilter]);

  const handleSelect = (event) => {
    if (event.expandedItems) {
      setExpanded(event.expandedItems);
    }
  };

  const handleClose = () => setViewGraph(false);

  return (
    <>
      <section className="tw-mb-10 custom-border-primary tw-rounded-md tw-p-5">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-5 tw-gap-5">
          <PricingFilters
            tripConfigState={tripConfigState}
            handleConfigChange={handleConfigChange}
          />
          <div className="tw-flex tw-gap-5 tw-items-center">
            <ButtonGroup
              buttons={ButtonGroupList}
              activeValue={activeValue}
              onActiveChange={setActiveValue}
              fontSize="sm"
            />
            <Button onClick={() => setViewGraph(true)} themeColor={"secondary"}>
              View Graph
            </Button>
          </div>
        </div>
        <div>
          <PricingConfigurationForm
            gridData={gridData}
            setGridData={setGridData}
            tripConfigState={tripConfigState}
            activeValue={activeValue}
          />
        </div>
      </section>
      <PanelBar
        isControlled={true}
        expanded={expanded}
        onSelect={handleSelect}
        expandMode={"single"}
      >
        <PanelBarItem title="Other charges">
          <div className="custom-template tw-p-5 tw-relative">
            <PricingOtherChanges
              activeValue={activeValue}
              regionId={selectedPricingRegionFilter?.regionId}
            />
          </div>
        </PanelBarItem>
        <PanelBarItem title="Pricing Change Logs">
          <div className="custom-template">
            <PricingChangeLogsTable />
          </div>
        </PanelBarItem>
      </PanelBar>

      <>
        {viewGraph && (
          <ModalLayout onClose={handleClose} title={"Pricing Logic Graph"}>
            <div className="tw-w-[60vw] tw-p-5">
              <ChartView data={gridData} />
            </div>
          </ModalLayout>
        )}
      </>
    </>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="tw-bg-white custom-border-primary tw-rounded-md tw-p-2">
        <span className="tw-block tw-text-base">{`Range: ${label}`}</span>
        <span className="tw-block tw-text-base">{`Evaluated Value: ₹ ${payload[0].value}`}</span>
      </div>
    );
  }
  return null;
};

export const ChartView = ({ data }) => {
  const chartData = data.map((item) => ({
    range: `${item.max} ${item?.timeUnit?.toLowerCase()}`,
    evaluatedValue: item.evaluatedValue,
  }));
  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="range" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Line
            isAnimationActive
            type="monotone"
            dataKey="evaluatedValue"
            stroke="#2756B3"
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default PricingConfigHome;

export const DriverConfig = {
  bgvStatus: [
    {
      label: "Initiated",
      value: "INITIATED",
    },
    {
      label: "Success",
      value: "SUCCESS",
    },
    {
      label: "Not Started",
      value: "NOT_STARTED",
    },
    {
      label: "Failed",
      value: "FAILED",
    },
  ],

  testDriveStatus: [
    {
      label: "Initiated",
      value: "INITIATED",
    },
    {
      label: "Success",
      value: "SUCCESS",
    },
    {
      label: "Not Started",
      value: "NOT_STARTED",
    },
    {
      label: "Failed",
      value: "FAILED",
    },
  ],

  transmissionTypePreferences: [
    {
      label: "Automatic",
      value: "AUTOMATIC",
    },
    {
      label: "Manual",
      value: "MANUAL",
    },
  ],

  carTypePreferences: [
    {
      label: "Hatchback",
      value: "HATCHBACK",
    },
    {
      label: "Sedan",
      value: "SEDAN",
    },
    {
      label: "SUV",
      value: "SUV",
    },
    {
      label: "Luxury",
      value: "LUXURY",
    },
  ],

  tripTypePreferences: [
    {
      label: "In City",
      value: "IN_CITY",
    },
    {
      label: "Outstation",
      value: "OUTSTATION",
    },
  ],

  driverPayoutType: [
    {
      label: "Variable",
      value: "VARIABLE",
    },
    {
      label: "Fixed",
      value: "FIXED",
    },
    {
      label: "True Fixed",
      value: "TRUE_FIXED",
    },
  ],

  onboardingStage: [
    {
      label: "Not Started",
      value: "NOT_STARTED",
    },
    {
      label: "Bank Verification",
      value: "BANK_VERIFICATION",
    },
    {
      label: "Onboarding form complete",
      value: "ONBOARDING_FORM_COMPLETE",
    },
    {
      label: "Document upload",
      value: "DOCUMENT_UPLOAD",
    },
    {
      label: "Preferences",
      value: "PREFERENCES",
    },
    {
      label: "Personal Details",
      value: "PERSONAL_DETAILS",
    },
    {
      label: "Profile Photo",
      value: "PROFILE_PHOTO",
    },
  ],

  blocked: [
    {
      label: "Active",
      value: false,
    },
    {
      label: "Blocked",
      value: true,
    },
  ],

  driverStatus: [
    {
      label: "All",
      value: "",
    },
    {
      label: "Online",
      value: "ONLINE",
    },
    {
      label: "Out of serviceable area",
      value: "OUT_OF_SERVICEABLE_AREA",
    },
    {
      label: "Trip acceptance timer running",
      value: "TRIP_ACCEPTANCE_TIMER_RUNNING",
    },
    {
      label: "In Trip",
      value: "IN_TRIP",
    },
    {
      label: "Offline",
      value: "OFFLINE",
    },
  ],

  driverAlerts: [
    {
      type: "BANKING_ALERT",
      code: "PAYOUT_ERROR",
      label: "accountNumberAlert",
    },
  ],

  languages: [
    {
      label: "English",
      value: "ENGLISH",
    },
    {
      label: "Hindi",
      value: "HINDI",
    },
    {
      label: "Kannada",
      value: "KANNADA",
    },
    {
      label: "Telugu",
      value: "TELUGU",
    },
    {
      label: "Punjabi",
      value: "PUNJABI",
    },
    {
      label: "Tamil",
      value: "TAMIL",
    },
    {
      label: "Odia",
      value: "ODIA",
    },
    {
      label: "Marathi",
      value: "MARATHI",
    },
    {
      label: "Malayalam",
      value: "MALAYALAM",
    },
  ],
  clientAccountTypes:[
    {
      label: "B2C",
      value: "B2C"
    },
    {
      label: "B2B",
      value: "CORPORATE"
    }
  ]
};


export const ACTIVITY_ACCORDIAN_TYPES = {
  ONLINE: {
    label: 'Online',
    value: 'ONLINE',
    color: '#22C55E',
  },
  TRIP: {
    label: 'Trip',
    value: 'TRIP',
    color: '#3B82F6',
  },
  OFFLINE: {
    label: 'Offline',
    value: 'OFFLINE',
    color: '#EF4444'
  },
  TRIP_CANCELED: {
    label: 'Trip Cancelled',
    value: 'TRIP_CANCELED',
    color: '#F97316',

  },
  NOT_OFFLINE: {
    label: 'Not Offline',
    value: 'NOT_OFFLINE',
  },
}

export const DRIVER_AVATAR_IMAGE = "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YXZhdGFyfGVufDB8fDB8fHww"

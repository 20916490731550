import React, { useEffect, useState } from "react";
import ModalLayout from "../../../common/ModalLayout";
import { FormInput } from "../../../../utils/forms/FormComponents";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import LoadingSpinner from "../../../common/LoadingSpinner";
import axiosService from "../../../../init/axios";
import { APIConfig } from "../../../../utils/constants/api.constants";
import {
  convertEnumToLabel,
  convertLabelToEnum,
  showErrorMessage,
  showToastMessage,
} from "../../../../utils/helpers";
import { useParams } from "react-router-dom";

function DriverChargesModal({ setRefreshTable, closeModal }) {
  const { driverId } = useParams();
  const [formState, setFormState] = useState({
    type: "",
    subtype: "",
    tripId: "",
  });

  const [dropdownData, setDropdownData] = useState({
    types: [],
    subtypes: [],
  });

  const [loading, setLoading] = useState(false);

  const handleChange = ({ name, value }) => {
    setFormState((prevState) => {
      const updatedState = { ...prevState, [name]: value };

      if (name === "type") {
        const selectedType = dropdownData.types.find(
          (item) => item.type === value
        );
        setDropdownData((prevDropdown) => ({
          ...prevDropdown,
          subtypes: selectedType?.subType || [],
        }));
        updatedState.subtype = ""; // Reset subtype when type changes
      }

      return updatedState;
    });
  };

  const fetchConfigData = async () => {
    setLoading(true);
    try {
      const url =
        process.env.REACT_APP_DRIVER_BASE_URL +
        APIConfig.driverManagement.dropdownConfig(
          "DRIVER_CANCELLATION_PATHFINDER_DROPDOWNS"
        );
      const response = await axiosService.get(url);

      const addDriverCharge = response?.data?.addDriverCharge || [];
      setDropdownData({ types: addDriverCharge, subtypes: [] });
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfigData();
  }, []);

  const handleAddChargeSubmit = async () => {
    const { tripId, type, subtype } = formState;
    const formattingData = {
      tripId,
      type,
      subType: subtype,
      driverId,
    };
    setLoading(true);
    const baseUrl = process.env.REACT_APP_TRIP_BASE_URL;
    const url = baseUrl + APIConfig.driverManagement.addDriverCharge;
    axiosService
      .put(url, formattingData)
      .then((data) => {
        showToastMessage("Charge added successfully");
        setRefreshTable((prev) => !prev);
        closeModal();
      })
      .catch((error) => {
        showErrorMessage(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isSubmitDisabled =
    !formState.type || !formState.subtype || !formState.tripId;

  return (
    <ModalLayout title={"Add Charge"} onClose={closeModal}>
      <div className="tw-w-[600px]">
        {loading ? (
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
            <LoadingSpinner height={70} width={70} color="grey" />
          </div>
        ) : (
          <>
            <div className="tw-flex tw-flex-col tw-gap-5 tw-rounded-md">
              {/* Type Dropdown */}
              <div>
                <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-400 tw-font-small">
                  Type
                </label>
                <DropDownList
                  name="type"
                  data={dropdownData?.types?.map((item) =>
                    convertEnumToLabel(item?.type)
                  )}
                  value={convertEnumToLabel(formState?.type)}
                  onChange={(e) =>
                    handleChange({
                      name: "type",
                      value: convertLabelToEnum(e?.target?.value),
                    })
                  }
                  placeholder="Select Type"
                />
              </div>

              {/* Subtype Dropdown */}
              <div>
                <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-400 tw-font-small">
                  Subtype
                </label>
                <DropDownList
                  name="subtype"
                  data={dropdownData?.subtypes?.map((item) => convertEnumToLabel(item)) || []}
                  value={convertEnumToLabel(formState?.subtype) || ""}
                  onChange={(e) =>
                    handleChange({ name: "subtype", value: convertLabelToEnum(e?.target?.value) || "" })
                  }
                  placeholder="Select Subtype"
                  disabled={!formState.type} // Disable until a type is selected
                />
              </div>

              {/* Trip ID Input */}
              <div>
                <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-400 tw-font-small">
                  Trip ID
                </label>
                <FormInput
                  name="tripId"
                  value={formState.tripId}
                  onChange={(e) =>
                    handleChange({ name: "tripId", value: e?.value })
                  }
                  placeholder="Enter Trip ID"
                  maxLength={30}
                />
              </div>
            </div>

            <Button
              className="tw-mt-4 tw-py-2"
              themeColor="primary"
              onClick={handleAddChargeSubmit}
              disabled={isSubmitDisabled}
            >
              Add Charge
            </Button>
          </>
        )}
      </div>
    </ModalLayout>
  );
}

export default DriverChargesModal;

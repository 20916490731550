import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { process } from '@progress/kendo-data-query';
import { APIConfig } from '../../../../../utils/constants/api.constants';
import GridLoader from '../../../../common/GridLoader';
import { convertEnumToLabel, getLabelFromEnum, stringToSentenceCase } from '../../../../../utils/helpers';

const TripStatusLogs = ({ tripId }) => {
  const baseUrl =
    process.env.REACT_APP_TRIP_BASE_URL +
    APIConfig.tripManagement.getTripStatusLogs(tripId);
  const [tripStatusLogs, setTripStatusLogs] = useState({
    data: [],
    total: 0,
  });

  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
  });

  const columns = [
    {
      id: "logId",
      field: "logId",
      title: "Log ID",
    },
    {
      id: "stage",
      field: "stage",
      title: "Log Type",
    },
    {
      id: "actionTime",
      field: "actionTime",
      title: "Timestamp",
    },
    {
      id: "driverId",
      field: "driverId",
      title: "Modified by Id",
    },
    {
      id:"actionBy",
      field:"actionBy",
      title:"Modified By",
      cell:(props)=>(
        <td>{convertEnumToLabel(props?.dataItem?.actionBy) || "N.A."}</td>
      )
    },
    {
      id: "eta",
      field: "eta",
      title: "ETA",
    },
  ];

  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };
  const dataReceived = (dataObj) => {
    const updatedLogs = dataObj.data.tripStatusLogs.map((log) => {
      const updatedLogDetails = getLabelFromEnum(["stage"], log, "trip");
      let logObj = {};
      if (logObj?.driverName) {
        logObj = {
          ...logObj,
          driverName: stringToSentenceCase(log?.driverName),
        };
      } else {
        logObj = { ...logObj, driverName: "" };
      }
      return { ...updatedLogDetails, ...logObj };
    });
    setTripStatusLogs({ ...dataObj, data: updatedLogs });
  };
  return (
    <div className='trip-details-table'>
      <Grid filterable={false} sortable={false} pageable={false} {...dataState}
        data={tripStatusLogs} 
        onDataStateChange={dataStateChange}>
        {
          columns.map(column => (
            <Column key={column?.id} field={column?.field} title={column?.title} cell={column?.cell}/>
          ))
        }
      </Grid>
      <GridLoader
        baseUrl={baseUrl}
        columns={columns}
        dataState={dataState}
        onDataReceived={dataReceived}
      />
    </div>
  );
};

export default TripStatusLogs;

import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";

function StatusToCancellation({ formData, handleChange, metadata, tripDetails, changedStatus, cancellationChargesAvailble, setCancellationChargesAvailable }) {
  const [showReasonSection, setShowReasonSection] = useState(false);

  const {
    message,
    options,
    waiverReasonSubReasonDetails = {},
    
  } = metadata || {};

  const { reasonLabel, subReasonLabel, data } = waiverReasonSubReasonDetails;

  const getCancellationCharges = (type, options) => {
    const charges = options?.find(
      (option) => option?.key === type?.toUpperCase()
    )?.cancellationCharges;
    setCancellationChargesAvailable(charges || false);
    return charges || false;
  };

  // Reset fields when cancellation type or reason changes
  useEffect(() => {
    handleChange("cancellationReason", null);
    handleChange("cancellationSubReason", null);
  }, [formData.cancellationType]);

  useEffect(() => {
    handleChange("cancellationSubReason", null);
  }, [formData.cancellationReason]);

  const renderMessage = () => {
    return message?.replace(
      /{selectedOption.label}|{selectedOption.cancellationCharges}/g,
      (matched) => {
        switch (matched) {
          case "{selectedOption.label}":
            return formData?.cancellationType?.toLowerCase() || "DRIVER";
          case "{selectedOption.cancellationCharges}":
            return getCancellationCharges(formData?.cancellationType, options) || "0";
          default:
            return matched;
        }
      }
    );
  };

  const renderReasonSection = () => {
    const reasons = data?.[formData?.cancellationType] || [];
    const subReasons =
      reasons.find((item) => item?.reason === formData?.cancellationReason)
        ?.subReasons || [];

    return (
      <div className="tw-flex tw-gap-8">
        <div className="tw-mt-4 tw-w-1/2">
          <label className="tw-font-bold tw-text-sm">{reasonLabel}</label>
          <DropDownList
            data={reasons.map((item) => item?.reason)}
            value={formData?.cancellationReason}
            onChange={(e) => handleChange("cancellationReason", e.target.value)}
            className="tw-w-full"
          />
        </div>
        <div className="tw-mt-4 tw-w-1/2">
          <label className="tw-font-bold tw-text-sm">{subReasonLabel}</label>
          <DropDownList
            data={subReasons}
            value={formData?.cancellationSubReason}
            onChange={(e) =>
              handleChange("cancellationSubReason", e.target.value)
            }
            className="tw-w-full"
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="tw-mt-6">
        <label className="tw-font-bold tw-text-sm">Cancellation type</label>
        <div className="tw-flex tw-gap-4">
          {options?.map((option) => (
            <label
              key={option?.key}
              className="tw-flex tw-items-center tw-text-sm tw-mr-8"
            >
              <input
                type="radio"
                name="cancellationType"
                value={option?.key}
                checked={formData.cancellationType === option?.key}
                onChange={(e) =>
                  handleChange("cancellationType", e.target.value)
                }
              />
              <span className="tw-ml-2">{option.label}</span>
            </label>
          ))}
        </div>
      </div>

      {formData?.cancellationType &&
        getCancellationCharges(formData?.cancellationType, options) && (
          <div className="tw-w-full tw-mt-2 tw-bg-gray-50 tw-rounded tw-p-3 border border-gray-200">
            <div className="tw-flex tw-justify-between tw-items-center">
              <label className="tw-text-xs tw-m-0">{renderMessage()}</label>
              <Button
                fillMode="outline"
                className="tw-text-primary hover:tw-bg-transparent hover:tw-text-primary tw-font-medium tw-text-xs"
                onClick={() => setShowReasonSection((prev) => !prev)}
              >
                Waive off
              </Button>
            </div>

            {showReasonSection && renderReasonSection()}
          </div>
        )}
    </div>
  );
}

export default StatusToCancellation;

import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { SignIn } from "./pages/SignIn.jsx";
import { Home } from "./pages/Home.jsx";
import { NotFound } from "./pages/NotFound.jsx";
import { getFromLocalStorage, authorizatedRoutes } from "./utils/helpers.js";
import { useSelector } from "react-redux";
import { getRoutingConfig } from "./config/routingConfig.js";
import { userPermissions } from "./config/userPermissionConfig.js";

export const CLIENT_ROUTES = ["/home/trip", "/home/addresses"];
export const ADMIN_ROUTES = [
  "/home/trip",
  "/home/driver",
  "/home/pricing",
  "/home/coupon/grievance",
  "/home/coupon/referral",
  "/home/customer-management",
  "/home/operations",
  "/home/subscriptions",
  "/home/region-management",
];

export const Routing = () => {
  const clientId = useSelector((state) => state.auth?.clientId);
  const isAuthenticated =
    useSelector((state) => state.auth.userLoggedIn) ||
    getFromLocalStorage("userLoggedIn");
  const userRoles = useSelector((state) => state.auth?.userRoles);

  const routingConfig = getRoutingConfig(userRoles, userPermissions);
  const validPaths = routingConfig
    .filter(
      (route) =>
        route.authRoute &&
        authorizatedRoutes(route.path, clientId, isAuthenticated)
    )
    .map((route) => route.path);

  const isPathValid = (pathname) => {
    return validPaths.includes(pathname);
  };

  return (
    <Routes>
      {/* Login Route */}
      <Route
        path="/login"
        element={
          isAuthenticated ? <Navigate to="/home/trip" replace /> : <SignIn />
        }
      />

      {/* Root Redirect */}
      <Route
        path="/"
        element={
          <Navigate to={isAuthenticated ? "/home/trip" : "/login"} replace />
        }
      />

      <Route
        path="/"
        element={
          isAuthenticated ? <Home /> : <Navigate to="/login" replace />
        }
      >
        {routingConfig
          .filter(
            (route) =>
              route.authRoute &&
              authorizatedRoutes(route.path, clientId, isAuthenticated)
          )
          .map((route) => {
            const Element = route.component;
            return (
              <Route key={route.path} path={route.path} element={<Element />} />
            );
          })}
      </Route>

      <Route
        path="/not-found"
        element={
          isAuthenticated && <NotFound />
        }
      />

      {/* Wildcard Route */}
      <Route
        path="*"
        element={
          isAuthenticated ? (
            isPathValid(window.location.pathname) ? (
              <Navigate to={window.location.pathname} replace />
            ) : (
              <NotFound />
            )
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
    </Routes>
  );
};

export default Routing;

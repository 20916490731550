import { createSlice } from "@reduxjs/toolkit";
import { saveToLocalStorage, saveToSession } from "../utils/helpers";

const componentStatesSlice = createSlice({
  name: "componentStates",
  initialState: {
    calendarToggle: true,
    tripManagementQueryParams: "",
    sidebarToggle: true,
  },
  reducers: {
    updateCalendarToggle: (state, action) => {
      state.calendarToggle = action.payload;
      saveToLocalStorage("componentStates", {
        ...state,
        calendarToggle: action.payload,
      });
    },
    updateTripManagementQueryParams: (state, action) => {
      state.tripManagementQueryParams = action.payload;
      saveToSession("tripManagementQueryParams", action.payload);
    },
    updateSidebarToggle: (state, action) => {
      state.sidebarToggle = action.payload;
      saveToLocalStorage("componentStates", {
        ...state,
        sidebarToggle: action.payload,
      });
    },
  },
});

export const {
  updateCalendarToggle,
  updateTripManagementQueryParams,
  updateSidebarToggle,
} = componentStatesSlice.actions;
export default componentStatesSlice.reducer;

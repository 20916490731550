import * as React from "react";
import * as ReactDOM from "react-dom";
import { toODataString } from "@progress/kendo-data-query";
import LoadingPanel from "./LoadingPanel";
import {
  convertObjectToParams,
  showErrorMessage,
  transformFilters,
} from "../../utils/helpers";
import axiosService from "../../init/axios";
import { debounce } from "lodash";

const GridLoader = ({
  baseUrl,
  columns,
  dataState,
  loader = null,
  onDataReceived,
  method = "get",
  body = "",
  loadingDisabled = false,
  refreshTable = false,
}) => {
  
  const lastSuccess = React.useRef("");
  const pending = React.useRef("");
  const prevDataState = React.useRef("");
  const [loading, setLoading] = React.useState(false);
  const gridRef = React.useRef(null); // Capture the grid container

  React.useEffect(() => {
    const delayedRequest = debounce(() => {
      requestDataIfNeeded();
    }, 500);

    delayedRequest();

    return delayedRequest.cancel;
  }, [dataState, refreshTable]);

  const requestDataIfNeeded = async () => {
    if (pending.current || toODataString(dataState) === lastSuccess.current) {
      if (
        dataState?.otherParams &&
        Object.keys(dataState?.otherParams)?.length > 0 &&
        prevDataState.current === JSON.stringify(dataState) &&
        !refreshTable
      ) {
        return;
      }
      if (!dataState?.otherParams && !refreshTable) {
        return;
      }
    }
    setLoading(true);
    loader && loader(true);
    pending.current = toODataString(dataState);
    let pageNumber = dataState.skip / dataState.take + 1;
    let pageSize = dataState.take;
    let params = {
      pageNumber,
      pageSize,
      ...(dataState.filter && transformFilters(columns, dataState.filter)),
      ...(dataState?.otherParams && dataState?.otherParams),
    };
    const queryParamString = convertObjectToParams(params);
    await axiosService[method](baseUrl + queryParamString, body)
      .then((data) => {
        lastSuccess.current = pending.current;
        pending.current = "";
        if (dataState?.otherParams) {
          prevDataState.current = JSON.stringify(dataState);
        }
        if (toODataString(dataState) === lastSuccess.current) {
          onDataReceived.call(undefined, {
            data: data.data,
            total: data.data.totalElements,
          });
        } else {
          requestDataIfNeeded();
        }
        setLoading(false);
        loader && loader(false);
      })
      .catch((error) => {
        showErrorMessage(error);
        pending.current = "";
        setLoading(false);
        loader && loader(false);
      });
  };

  if (loadingDisabled) return null;
  return loading ? (
    <div className="" ref={gridRef}>
      {loading && <LoadingPanel/>}
    </div>
  ) : null;
};

export default GridLoader;

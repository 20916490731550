import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { getFromLocalStorage } from "../utils/helpers";
import { getDrawerConfig } from "../config/drawerConfig";
import Sidebar from "./Sidebar";
import SidebarHamburger from "./SidebarHamburger";
import SidebarWrapper from "./SidebarWrapper";

export const DrawerContainer = (props) => {
  const isAuthenticated =
    useSelector((state) => state.auth.userLoggedIn) ||
    getFromLocalStorage("userLoggedIn");
  const user = useSelector((state) => state.auth?.userDetails);
  const clientId = useSelector((state) => state.auth?.clientId);
  const userRoles = useSelector((state) => state.auth?.userRoles);
  const [isLoading, setIsLoading] = useState(true);
  const [collapsable, setCollapsable] = useState(true);

  useEffect(() => {
    if (user && clientId !== undefined && isLoading) {
      setIsLoading(false);
    }
  }, [user, clientId, isLoading]);

  const tripManagementQueryParams = useSelector(
    (state) => state.component.tripManagementQueryParams
  );

  const drawerConfig = getDrawerConfig(userRoles, tripManagementQueryParams);

  return (
    <>
      <main className="tw-flex tw-items-start tw-h-[100dvh] tw-overflow-hidden">
        <SidebarWrapper>
          <Sidebar
            items={drawerConfig}
            isLoading={isLoading}
            user={user}
            clientId={clientId}
            isAuthenticated={isAuthenticated}
            collapsable={collapsable}
          />
        </SidebarWrapper>
        {props.children}
        <Outlet />
      </main>
    </>
  );
};

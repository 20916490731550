import BulkOperationsManagement from "../pages/BulkOperationsManagement.jsx";
import CustomerManagement from "../pages/CustomerManagement.jsx";
import DriverPayoutManagement from "../pages/DriverPayoutManagement.jsx";
import GrievanceCouponManagement from "../pages/coupons/GrievanceCouponManagement.jsx";
import ReferralCouponManagement from "../pages/coupons/ReferralCouponManagement.jsx";
import AssignDriver from "../pages/trip-management/AssignDriver.jsx";
import SubscriptionPackage from "../pages/SubscriptionPackage.jsx";
import SubscriptionDetails from "../components/subscription-package/SubscriptionDetails.jsx";
import AllDriverManagement from "../pages/AllDriverManagement.jsx";
import SubscriptionCycleDetails from "../components/subscription-package/SubscriptionCycleDetails.jsx";
import SubscriptionCycleDayDetails from "../components/subscription-package/SubscriptionCycleDayDetails.jsx";
import TripManagement from "../pages/trip-management/TripManagement.jsx";
import DriverManagement from "../pages/DriverManagement.jsx";
import PricingManagement from "../pages/PricingManagement.jsx";
import AddressesPage from "../pages/AddressesPage.jsx";
import RegionManagement from "../pages/RegionManagement.jsx";
import { userPermissions } from "./userPermissionConfig.js";
import { checkMatchingRoles } from "../utils/helpers.js";

export const getRoutingConfig = (userRoles) => [
  {
    path: "/home/trip",
    component: TripManagement,
    authRoute: true,
  },
  {
    path: "/home/trip/:tripId/details/",
    component: TripManagement,
    authRoute: true,
  },
  {
    path: "/home/trip/:tripId/assign-driver",
    component: AssignDriver,
    authRoute: true,
  },
  {
    path: "/home/driver",
    component: DriverManagement,
    authRoute: true,
  },
  {
    path: "/home/driver/all",
    component: AllDriverManagement,
    authRoute: true,
  },
  {
    path: "/home/driver/:driverId/profile",
    component: DriverManagement,
    authRoute: true,
  },
  {
    path: "/home/driver/:driverId/account",
    component: DriverManagement,
    authRoute: true,
  },
  {
    path: "/home/driver/:driverId/online-activity",
    component: DriverManagement,
    authRoute: true,
  },
  {
    path: "/home/driver/payout",
    component: DriverPayoutManagement,
    authRoute: true,
  },
  {
    path: "/home/pricing",
    component: PricingManagement,
    authRoute: true,
  },
  {
    path: "/home/coupon/grievance",
    component: GrievanceCouponManagement,
    authRoute: true,
  },
  {
    path: "/home/coupon/referral",
    component: ReferralCouponManagement,
    authRoute: true,
  },
  {
    path: "/home/customer-management",
    component: CustomerManagement,
    authRoute: true,
  },
  {
    path: "/home/operations",
    component: BulkOperationsManagement,
    authRoute: true,
  },
  {
    path: "/home/subscriptions",
    component: SubscriptionPackage,
    authRoute: true,
  },
  {
    path: "/home/subscriptions/:subId/details",
    component: SubscriptionDetails,
    authRoute: true,
  },
  {
    path: "/home/subscriptions/:subId/cycle/:cycleId/details",
    component: SubscriptionCycleDetails,
    authRoute: true,
  },
  {
    path: "/home/subscriptions/:subId/cycle/:cycleId/day/:tripId/details",
    component: SubscriptionCycleDayDetails,
    authRoute: true,
  },
  {
    path: "/home/addresses",
    component: AddressesPage,
    authRoute: true,
  },
  {
    path: "/home/region-management",
    component: RegionManagement,
    authRoute: checkMatchingRoles(
      userRoles,
      userPermissions.regionMangementModification
    ),
  },
];

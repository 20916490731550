import React, { useEffect, useState } from "react";
import ModalLayout from "../../../common/ModalLayout";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { APIConfig } from "../../../../utils/constants/api.constants";
import axiosService from "../../../../init/axios";
import { showErrorMessage, showToastMessage } from "../../../../utils/helpers";
import LoadingSpinner from "../../../common/LoadingSpinner";

function DriverWaiveOffModal({setRefreshTable, closeModal, selectedDriverRow }) {
  const [formData, setFormData] = useState({
    reason: "",
    subReason: "",
  });

  const [dropdownData, setDropdownData] = useState({
    reasons: [],
    subReasons: [],
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (key, value) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData, [key]: value };

      if (key === "reason") {
        const selectedReason = dropdownData.reasons.find(
          (item) => item.reason === value
        );
        setDropdownData((prevDropdownData) => ({
          ...prevDropdownData,
          subReasons: selectedReason?.subReason || [],
        }));
        updatedData.subReason = ""; // Reset subReason
      }

      return updatedData;
    });
  };

  useEffect(() => {
    const fetchConfigData = async () => {
      setLoading(true);

      try {
        const url =
          process.env.REACT_APP_DRIVER_BASE_URL +
          APIConfig.driverManagement.dropdownConfig(
            "DRIVER_CANCELLATION_PATHFINDER_DROPDOWNS");
        const response = await axiosService.get(url);
        const waiveOffData = response?.data?.waiveOffDriverCharge || [];

        setDropdownData({
          reasons: waiveOffData,
          subReasons: [],
        });
      } catch (error) {
        console.error("Waive-Off Data Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchConfigData();
  }, []);

  const handleWaiveOffSubmit = async () => {
    setLoading(true);

    try {
      const url =
        process.env.REACT_APP_TRIP_BASE_URL +
        APIConfig.driverManagement.waiveOffDriver;
      
        const formattingSubmitData = {
          metadata:formData,
          tripId:selectedDriverRow?.tripId,
          requesterId:selectedDriverRow?.driverId,
          disputeType:selectedDriverRow?.subType
        }
      await axiosService.post(url, formattingSubmitData);
      showToastMessage("Waive-Off submitted successfully");
      setRefreshTable((prev) => !prev ? true : true);
      closeModal();
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const isSubmitDisabled = !formData.reason || !formData.subReason;

  return (
    <ModalLayout title="Select Reason" onClose={closeModal}>
      <div className="tw-w-[600px]">
        {loading ? (
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
            <LoadingSpinner height={70} width={70} color="grey" />
          </div>
        ) : (
          <>
            <div className="tw-flex tw-gap-5 tw-rounded-md">
              {/* Reason Dropdown */}
              <div className="tw-w-1/2">
                <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-400 tw-font-small">
                  Reason
                </label>
                <DropDownList
                  data={dropdownData.reasons.map((item) => item.reason)}
                  value={formData.reason}
                  onChange={(e) => handleChange("reason", e.value)}
                  placeholder="Select Reason"
                />
              </div>

              {/* Sub-Reason Dropdown */}
              <div className="tw-w-1/2">
                <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-400 tw-font-small">
                  Sub Reason
                </label>
                <DropDownList
                  data={dropdownData.subReasons}
                  value={formData.subReason}
                  onChange={(e) => handleChange("subReason", e.value)}
                  placeholder="Select Sub Reason"
                  disabled={!formData.reason} // Disable until a reason is selected
                />
              </div>
            </div>
            {/* Waive Off Button */}
            <Button
              themeColor="primary"
              type="solid"
              className="tw-mt-6"
              disabled={isSubmitDisabled} // Disable button until all fields are filled
              onClick={handleWaiveOffSubmit}
            >
              Waive Off
            </Button>
          </>
        )}
      </div>
    </ModalLayout>
  );
}

export default DriverWaiveOffModal;

import React, { useCallback, useMemo, useRef } from "react";
import { Marker, Polyline } from "@react-google-maps/api";
import GoogleMapsWrapper from "../../../../maps/GoogleMapsWrapper";
import { mapDefaults } from "../../../../maps/defaults";
import startPointSymbol from "../../../../../assets/start-point-2.png";
import endPointSymbol from "../../../../../assets/end-point-2.png";
import driverSymbol from '../../../../../assets/driver2.png'
function BookingModificationMap({ tripDetails, metadata }) {
  const containerStyle = { width: "100%", height: "300px" };
  const mapRef = useRef(null);

  // Extract locations from metadata
  const {
    expectedSourceLocation,
    expectedDestinationLocation,
    driverLocation,
  } = metadata;

  // Map center based on provided locations
  const center = useMemo(() => {
    if (!expectedSourceLocation || !expectedDestinationLocation) {
      return { lat: 0, lng: 0 }; // Default fallback
    }
    return {
      lat: (expectedSourceLocation.lat + expectedDestinationLocation.lat) / 2,
      lng: (expectedSourceLocation.lng + expectedDestinationLocation.lng) / 2,
    };
  }, [expectedSourceLocation, expectedDestinationLocation]);

  const onLoad = useCallback(
    (map) => {
      mapRef.current = map;
      if (mapRef.current) {
        const bounds = new window.google.maps.LatLngBounds();
        if (driverLocation) bounds.extend(driverLocation);
        if (expectedSourceLocation) bounds.extend(expectedSourceLocation);
        if (expectedDestinationLocation)
          bounds.extend(expectedDestinationLocation);
        mapRef.current.fitBounds(bounds);
      }
    },
    [driverLocation, expectedSourceLocation, expectedDestinationLocation]
  );

  const options = useMemo(() => mapDefaults.statusModificationMap.options, []);

  // Define path for the polyline
  const path = useMemo(() => {
    const points = [];
    if (driverLocation) points.push(driverLocation);
    if (expectedSourceLocation) points.push(expectedSourceLocation);
    if (expectedDestinationLocation) points.push(expectedDestinationLocation);
    return points;
  }, [expectedSourceLocation, driverLocation, expectedDestinationLocation]);

  return (
    <div className="tw-bg-black tw-col-span-2">
      <GoogleMapsWrapper
        containerStyle={containerStyle}
        options={options}
        center={center}
        zoom={mapDefaults.statusModificationMap.zoom}
        onLoad={onLoad}
        mapRef={mapRef.current}
      >
        {mapRef.current && (
          <>
            {expectedSourceLocation && (
              <Marker
                position={expectedSourceLocation}
                icon={{
                  url: startPointSymbol,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                zIndex={1} 
              />
            )}
            {driverLocation && (
              <Marker
                position={driverLocation}
                icon={{
                  url: driverSymbol,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                zIndex={2} 
              />
            )}
            {expectedDestinationLocation && (
              <Marker
                position={expectedDestinationLocation}
                icon={{
                  url: endPointSymbol,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                zIndex={1} 
              />
            )}
            {path.length > 1 && (
              <Polyline
                path={path}
                options={{
                  strokeColor: "#000",
                  strokeOpacity: 0.6,
                  strokeWeight: 3,
                  icons: [
                    {
                      icon: {
                        path: "M 0,-1 0,1",
                        strokeOpacity: 1,
                        scale: 3,
                      },
                      offset: "0",
                      repeat: "10px",
                    },
                  ],
                }}
              />
            )}
          </>
        )}
      </GoogleMapsWrapper>
    </div>
  );
}

export default BookingModificationMap;
